<template>
  <div id="app" class="flex flex-col min-h-screen">
    <NavBar />
    <EventPopper v-if="hasEvent && showEvent" :hideEvent="hideEvent"  />
    <router-view class="pt-20 flex-grow" />
    <modals-container></modals-container>
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/FooterComponent.vue'
import EventPopper from '@/components/EventPopper.vue'
import { ModalsContainer } from 'vue-final-modal'
import { mapGetters } from 'vuex'
// $vfm,
// VueFinalModal,
// ModalsContainer } from 'vue-final-modal'

const eventAvailabelRoutes = ['home']

export default {
  components: { NavBar, ModalsContainer, Footer, EventPopper },
  // metaInfo: {
  //   title: 'PayWide'
  // },
  data () {
    return {
      eventValid: '2024-01-01'
    }
  },
  computed: {
    ...mapGetters('WALLET', ['ADDRESS']),
    hasEvent () {
      const expire = new Date(this.eventValid)
      return expire.getTime() >= new Date().getTime()
    },
    hideEvent () {
      return !!this.$store.getters['CARD/REFERRAL_CODE']
    },
    showEvent () {
      return eventAvailabelRoutes.includes(this.$route.name)
    }
  },
  mounted () {
    this.$store.dispatch('WALLET/INIT_WEB3MODAL')
  },
  methods: {
    setAddress () {
      //
    }
  }
}
</script>

<style lang="scss">
// @font-face {
//   font-family: "Montserrat";
//   src: local("Montserrat"),
//     url('@/assets/font/Montserrat-Medium.ttf') format("truetype");
//   font-weight: 400;
// }
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("@/assets/font/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("@/assets/font/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}
#app {
  font-family: "PingFang HK", Roboto, "Noto Sans", Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  color: #999999;
}

nav {
  padding: 30px;

  a {
    @apply font-semibold;
    color: #1e1e1e;
    &:hover {
      color: #5a5a5a;
    }
  }
}
</style>
