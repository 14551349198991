import ABI from '@/constants/abi'
import mainnet from './uniswap/mainnet.json'
import sepolia from './uniswap/sepolia.json'
import holesky from './uniswap/holesky.json'
import bnbtTokens from './pancakeswap/bnbt.json'
import bnbTokens from './pancakeswap/bnb.json'
// import polygonTestnet from './polygon/testnet.json'
import polygonMainnet from './polygon/mainnet.json'
import avalancheTestnet from './avalanche/testnet.json'
import avalancheMainnet from './avalanche/mainnet.json'
import arbitrumMainnet from './arbitrum/mainnet.json'
import optimismMainnet from './optimism/mainnet.json'
// import { SWAP_ROUTER_02_ADDRESSES } from '@uniswap/sdk-core'
// const TOKENS = {
const eth = {
  symbol: 'ETH',
  decimals: 18,
  baseUnit: 1e18,
  isNative: true
}
// const bnb = {
//   symbol: 'BNB',
//   decimals: 18,
//   baseUnit: 1e18,
//   isNative: true
// }
// }
const TOKENS = {
  1: [eth, ...iterateToken(mainnet)],
  56: [...iterateToken(bnbTokens)],
  // 97: [bnb, ...iterateToken(bnbtTokens)],
  97: [...iterateToken(bnbtTokens)],
  137: [...iterateToken(polygonMainnet)],
  43114: [...iterateToken(avalancheMainnet)],
  43113: [...iterateToken(avalancheTestnet)],
  17000: [...iterateToken(holesky)], // not support uniswap
  11155111: [eth, ...iterateToken(sepolia)],
  42161: [...iterateToken(arbitrumMainnet)],
  10: [...iterateToken(optimismMainnet)]
}

function iterateToken (tokenArray) {
  return tokenArray.map(el => {
    const { decimals, logoURI } = el
    el.baseUnit = Math.pow(10, decimals)
    if (logoURI.includes('ipfs://')) {
      el.logoURI = logoURI.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
    }
    return el
  })
}
// function iterateToken (tokenArray, tokensRef) {
//   for (const token of tokenArray) {
//     const { address, symbol, decimals, chainId, logoURI, max } = token
//     const key = symbol.toLowerCase()
//     if (!tokensRef[key]) {
//       tokensRef[key] = {
//         symbol,
//         decimals,
//         logoURI,
//         max
//       }
//       tokensRef[key].baseUnit = Math.pow(10, decimals)
//       if (logoURI.includes('ipfs://')) {
//         tokensRef[key].logoURI = logoURI.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
//       }
//     }
//     tokensRef[key][chainId] = address
//   }
// }
// iterateToken(mainnet, TOKENS)
// iterateToken(ropsten, TOKENS)
// iterateToken(bnb, TOKENS)
// iterateToken(bnbt, TOKENS)

// const TOKEN_ADDRESS = {
//   usdt: {
//     1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
//     3: '0x110a13FC3efE6A245B50102D2d79B3E76125Ae83'
//   }, // chainID 3
//   usdc: {
//     // 1: '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
//     1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
//     3: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F'
//   } // chainID 1
// }

// uniswap V3 SwapRouter02
// ref: https://docs.uniswap.org/contracts/v3/reference/deployments
// const SWAP_ADDRESS = {
//   1: SWAP_ROUTER_02_ADDRESSES(1),
//   56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
//   97: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
//   11155111: SWAP_ROUTER_02_ADDRESSES(11155111),
//   17000: '' // undefined
// }
export {
  ABI,
  // TOKEN_ADDRESS,
  TOKENS
  // SWAP_ADDRESS
}
