<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <div class="-mx-8 my-16 md:mt-0 md:mb-7">
        <simple-picture
          class="block w-full mx-auto"
            src="@/assets/images/modals/kv-success.png"
            alt="Success"
            x="2"
          />
      </div>
      <div class="mb-2">
        <button
          type="button"
          class="btn btn-fixed-width"
          @click="close"
        >OK</button>
      </div>
    </div>
    <button class="modal__close" @click="close">
      <icon-close-popup />
    </button>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
  components: { VueFinalModal },
  name: 'AlertSuccess',
  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
