<template>
  <div class="">
    <label id="listbox-label" class="block text-sm leading-5 font-medium text-gray-700">
      <slot name="SelectLabel"></slot>
    </label>

    <div class="relative" v-click-outside="closeDropdown">
      <div class="absolute w-full rounded-3xl z-20">
        <button type="button" @click="openDropdown" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
          class="cursor-pointer relative w-full rounded-3xl bg-primary pl-3 pr-10 py-3 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150"
          :disabled="disabled" v-loading="disabled"
        >
          <div v-lazy-container="{ selector: 'img' }" class="flex items-center space-x-3">
            <img :data-src="selectedItem && selectedItem.image" :data-loading="coinDefault" :data-error="coinDefault" alt="selectedItem.value" class="flex-shrink-0 h-[26px] w-[26px] my-[-1px] rounded-full" />
            <span class="block truncate text-dark">
              {{ value }}
            </span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pointer-events-none">
            <img src="@/assets/images/icons/angle-down.svg">
            <!-- <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="none" stroke="currentColor">
              <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->
          </span>
        </button>
      </div>

      <!-- Select popover, show/hide based on select state. -->
      <div v-show="isOpen" class="absolute w-full pb-1 z-10">
        <div class=" w-full rounded-3xl bg-white pt-12 shadow-lg">
          <div class="bg-white p-2 border-b border-b-[#E6E6E6]">
            <div class="input-field">
              <img class="search-icon" src="@/assets/images/icons/search.svg" alt="Search">
              <input type="text" v-model="filterKey">
            </div>
          </div>
          <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-56 rounded-md pb-6 mb-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none">
            <!--
              Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
              Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
            -->
            <li tabindex="0" @click="select(d)" id="listbox-item-0" role="option" v-for="d in filteredItems" v-bind:key="d.value" class="text-gray-900 cursor-pointer select-none relative py-4 pl-3 pr-9 hover:text-dark hover:bg-[#E6E6E6] focus:outline-none focus:text-dark focus:bg-indigo-600">
              <div v-lazy-container="{ selector: 'img' }" class="flex items-center space-x-3">
                <img :data-src="d.image" :data-loading="coinDefault" :data-error="coinDefault" :alt="d.value" class="flex-shrink-0 h-6 w-6 rounded-full" />
                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                <span class="block truncate">
                  {{ d.value }}
                </span>
              </div>
              <!--
                Checkmark, only display for selected option.
                Highlighted: "text-white", Not Highlighted: "text-indigo-600"
              -->
              <span v-show="isSelected(d)" class="absolute inset-y-0 right-0 flex items-center pr-4">
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                </svg>
              </span>
            </li>
            <!-- More options... -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { TOKENS } from '@/constants'
import PNG from '@/assets/images/currency'

import coinDefault from '@/assets/images/currency/coin.svg'

// console.log(PNG['bch.png'])
// const defaultCurrencyArray = JSON.parse(process.env.VUE_APP_SUPPORTED_CURRENCY)
// console.log(defaultCurrencyArray[0])
// console.log(defaultCurrencyArray.map(el => {
//   return {
//     value: el,
//     image: PNG[`${el.toLowerCase()}.png`]
//   }
// }))
export default {
  name: 'CurrencySelect',
  props: {
    // data: Array,
    value: String,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      filterKey: '',
      coinDefault
    }
  },
  computed: {
    chainId () {
      return this.$store.getters['WALLET/CHAIN_ID']
    },
    data () {
      if (this.chainId) {
        const chainItems = TOKENS[this.chainId]
        // Object.values(TOKENS).filter(item => item[this.chainId])
        return chainItems.map(el => {
          const image = (el.logoURI) || PNG[`${el.symbol.toLowerCase()}.png`]
          return {
            value: el.symbol,
            image
          }
        })
      }
      return []
    },
    selectedItem () {
      if (this.value === null || this.value === '') {
        this.$emit('valueSelect', this.data[0].value)
        return this.data[0].image
      }
      return this.data.find(element => element.value === this.value)
    },
    filteredItems () {
      if (this.filterKey === '') {
        return this.data
      }
      return this.data.filter(item => {
        return item.value.includes(this.filterKey.toUpperCase())
      })
    }
  },
  methods: {
    isSelected (item) {
      return this.value === item.value
    },
    closeDropdown () {
      this.isOpen = false
    },
    openDropdown () {
      this.filterKey = ''
      this.isOpen = true
    },
    select (item) {
      this.isOpen = false
      this.$emit('valueSelect', item.value)
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.input-field {
  @apply relative;
  @apply block text-left;
  @apply bg-white;
  @apply border border-[#E6E6E6];
  @apply rounded-2xl h-[3rem] px-2;
  input {
    @apply bg-transparent;
    @apply focus:outline-none;
    @apply text-left text-dark text-base;
    @apply py-3 max-w-full pl-10;
  }
  .search-icon {
    @apply absolute left-0 w-12 h-auto;
  }
  &.error {
    @apply border-2 border-red-400;
  }
}
::v-deep .el-loading-mask {
  @apply rounded-3xl hover:cursor-not-allowed;
  background: rgba(0, 0, 0, 0.5);
}
</style>
