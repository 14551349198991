<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <simple-picture img-class="max-w-[387px] w-full mx-auto mt-[22px] mb-[30px]" src="@/assets/images/modals/kv-pending.png" alt="" x="2" />
      <div class="modal__title heading text-4xl leading-11 text-dark mb-10">
        {{ $t('modal.waiting-confirm.processing') }}
        <template v-if="confirmations>=0&&completedConfirmations>0">
          <span>(</span>
          <span v-if="confirmations">{{confirmations}}</span>
          <span v-else>0</span>
          <span v-if="completedConfirmations" >/{{completedConfirmations}}</span>
          <span>)</span>
        </template>
      </div>
      <!-- <div class="text-primary font-semibold">
        Your order is in process. Your will get your card information after.
      </div> -->
      <section class="notice max-w-[438px] mx-auto text-left font-semibold">
        <!-- <div>Notice</div> -->
        <ul class="list-disc list-outside pl-6">
          <li>{{ $t('modal.waiting-confirm.notice.0') }}</li>
          <li>{{ $t('modal.waiting-confirm.notice.1') }}</li>
          <li>{{ $t('modal.waiting-confirm.notice.2') }}</li>
        </ul>
      </section>
    </div>
    <!-- <button class="modal__close" @click="close">
      <font-awesome-icon :icon="['fas', 'xmark']" class="text-gray-500" size="2x" />
    </button> -->
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import SimplePicture from '@/components/SimplePicture.vue'

export default {
  components: { VueFinalModal, SimplePicture },
  name: 'WaitingTopUpConfirm',
  inheritAttrs: false,
  data () {
    return {
      polling: null,
      confirmations: null,
      completedConfirmations: null,
      txCompleted: false
    }
  },
  computed: {
    CURRENT_ID () {
      return this.$store.getters['CARD/CURRENT_ID']
    },
    TX_HASH () {
      return this.$store.getters['WALLET/TX_HASH']
    },
    TX_CURRENCY () {
      return this.$store.getters['WALLET/TX_CURRENCY']
    },
    PROVIDER () {
      return this.$store.getters['WALLET/PROVIDER']
    },
    TOPUP_API_COMPLETED () {
      return this.$store.getters['CARD/TOPUP_API_COMPLETED']
    }
  },
  watch: {
    TX_CURRENCY (val) {
      this.completedConfirmations = null
      if (this.TX_CURRENCY) {
        this.getCompletedConfirmations()
      }
    },
    TX_HASH (val) {
      this.pollTxStatus()
    }
  },
  created () {
    this.getCompletedConfirmations()
  },
  methods: {
    handleClick () {
      this.$store.dispatch('MODAL/CLOSE')
      const path = this.localePath({ name: 'cardDetail', params: { id: this.CURRENT_ID } })
      this.$router.push(path)
    },
    async getCompletedConfirmations () {
      if (this.TX_CURRENCY && !this.completedConfirmations) {
        const result = await this.$store.dispatch('CARD/GET_CONFIRMATIONS', this.TX_CURRENCY)
        if (result.confirmations) {
          this.completedConfirmations = result.confirmations
        }
      }
    },
    pollTxStatus () {
      if (this.polling) {
        clearInterval(this.polling)
      }
      this.polling = setInterval(async () => {
        const hash = this.TX_HASH
        const currency = this.TX_CURRENCY
        if (!hash || !currency) {
          clearInterval(this.polling)
        }
        if (!this.txCompleted && hash) {
          const provider = this.PROVIDER
          if (provider) {
            try {
              const tx = await provider.getTransaction(hash)
              if (tx) {
                this.confirmations = tx.confirmations
                // check completed
                if (this.completedConfirmations && this.confirmations >= this.completedConfirmations) {
                  this.txCompleted = true
                }
              }
            } catch (e) {
              console.warn('fetch transaction error', e.message)
            }
          }
          if (this.TOPUP_API_COMPLETED && this.txCompleted) {
            this.$store.dispatch('MODAL/OPEN', 'TopUpComplete')
            clearInterval(this.polling)
          }
        }
      }, 5000)
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
