<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <section class="section-kv flex flex-col justify-between">
      <div class="container pt-20 px-7">
        <!-- <img class="w-2/3" src="@/assets/images/card@2x.png" alt="" srcset=""> -->
        <h1 class="h1 max-w-3xl mx-auto mb-10" v-html="$t('home.title')"></h1>
        <h2 class="text-xl text-dark font-semibold mb-2 mx-auto lg:mb-5 max-w-[854px]">{{ $t('home.subtitle-1') }}</h2>
        <div class="text-xl text-dark mx-auto mb-10 lg:mb-10 max-w-[854px] " v-html="$t('home.subtitle-2')"></div>
        <div class="mb-[60px]">
          <button v-if="!IS_CONNECTED"
            id="home-connect-wallet-btn"
            type="button"
            class="btn btn-fixed-width"
            @click="handleBuyClick"
          >
            {{ $t('button.connect-wallet') }}
          </button>
          <router-link
            v-else-if="USER_ACTIVE"
            id="home-card-list-btn"
            class="btn btn-fixed-width"
            :to="localePath({ name: 'cardList' })"
          >
            {{ $t('button.my-card') }}
          </router-link>
          <!-- <button
            v-else-if="USER_ACTIVE"
            class="btn btn-fixed-width disabled"
          >
            {{ $t('button.my-card') }}
          </button> -->
          <button v-else-if="!USER_ACTIVE"
            id="home-get-card-btn"
            type="button"
            class="btn btn-fixed-width"
            @click="handleBuyClick"
          >
            {{ $t('button.get-free-card') }}
          </button>
        </div>
      </div>
      <div class="hidden md:flex justify-center overflow-hidden w-full h-[347px]">
        <simple-picture img-class="block max-w-none mx-auto w-[1440px]" src="@/assets/images/kv-product.png" alt="Product" x="2" />
      </div>
      <div class="flex md:hidden justify-center overflow-hidden w-full h-[216px]">
        <simple-picture img-class="block max-w-none mx-auto w-[375px]" src="@/assets/images/kv-product-mobile.png" alt="Product on mobile" x="2" />
      </div>
    </section>

    <section class="bg-gray-100">
      <div class="container py-[60px]">
        <div class="feature-container">
          <div class="feature-item">
            <div class="feature-img">
              <simple-picture img-class="w-full" src="@/assets/images/feat-private.png" alt="Private" x="2" />
            </div>
            <div class="feature-content">
              <div class="feature-item-title">{{ $t('home.feature.private.title') }}</div>
              <div class="feature-item-text">{{ $t('home.feature.private.content') }}</div>
            </div>
          </div>
          <div class="feature-item">
            <div class="feature-img">
              <simple-picture img-class="w-full" src="@/assets/images/feat-secure.png" alt="Secure" x="2" />
            </div>
            <div class="feature-content">
              <div class="feature-item-title">{{ $t('home.feature.secure.title') }}</div>
              <div class="feature-item-text">{{ $t('home.feature.secure.content') }}</div>
            </div>
          </div>
          <div class="feature-item">
            <div class="feature-img">
              <simple-picture img-class="w-full" src="@/assets/images/feat-3ds.png" alt="3DS Verification" x="2" />
            </div>
            <div class="feature-content">
              <div class="feature-item-title">{{ $t('home.feature.3ds.title') }}</div>
              <div class="feature-item-text">{{ $t('home.feature.3ds.content') }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-gray-100">
      <div class="container flex flex-col items-center py-[60px]">
        <div class="h2 mb-2 px-7 sm:px-0">{{ $t('home.benefit.title') }}</div>
        <div class="text-xl font-semibold px-7 sm:px-0 mb-12">{{ $t('home.benefit.subtitle') }}</div>
        <!-- <div class="grid grid-cols-1 md:grid-cols-3 gap-16 px-12 md:px-4 lg:px-20 w-full"> -->
        <div class="benefit-container">
          <div class="benefit-card">
            <simple-picture img-class="w-full" src="@/assets/images/feat-fast.jpg" alt="Fast and secure, no top-up amount limit" x="2" />
            <div class="content-wrap">
              <div class="title">{{ $t('home.benefit.0.title') }}</div>
              <div class="content">{{ $t('home.benefit.0.content') }}</div>
            </div>
          </div>
          <div class="benefit-card">
            <simple-picture img-class="w-full" src="@/assets/images/feat-dao-nft.jpg" alt="Enter the world of DAO through NFTs" x="2" />
            <div class="content-wrap">
              <div class="title">{{ $t('home.benefit.1.title') }}</div>
              <div class="content">
                <div>{{ $t('home.benefit.1.content') }}</div>
              </div>
            </div>
          </div>
          <div class="benefit-card">
            <simple-picture img-class="w-full" src="@/assets/images/feat-instantly.jpg" alt="Generate card instantly and easily" x="2" />
            <div class="content-wrap">
              <div class="title">{{ $t('home.benefit.2.title') }}</div>
              <div class="content">{{ $t('home.benefit.2.content') }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="bg-gray-100">
      <div class="container pt-[60px] pb-[120px]">
        <div class="font-heading text-2xl text-center text-dark mb-10 px-7">{{ $t('home.merchant') }}</div>
        <div class="merchant-items flex justify-center flex-wrap gap-5">
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/Amazon.png" alt="Amazon" x="2" />
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/PayPal.png" alt="PayPal" x="2" />
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/aws.png" alt="AWS" x="2" />
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/AppStore.png" alt="App Store" x="2" />
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/Facebook.png" alt="Facebook" x="2" />
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/Etsy.png" alt="Etsy" x="2" />
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/GoogleAds.png" alt="Google Ads" x="2" />
          <simple-picture img-class="merchant-item" src="@/assets/images/merchants/Microsoft.png" alt="Microsoft" x="2" />
        </div>
      </div>
    </section> -->

    <section id="pricing" class="bg-gray-300">
      <div class="container py-[120px] px-7">
        <div class="h2">{{ $t('home.program.title') }}</div>
        <div class="pricing-card mb-10">
          <div class="pricing-program">
            <simple-picture class="pricing-program-img" src="@/assets/images/pricing-classic-program.png" alt="Classic Program" x="2" />
            <div class="pricing-program-content flex-grow">
              <div class="pricing-program-title">{{ $t('home.program.classic.title') }}</div>
              <div class="pricing-program-subtitle max-w-[492px]">{{ $t('home.program.classic.subtitle') }}</div>
              <ul class="pricing-program-features">
                <li>{{ $t('home.program.classic.1') }}</li>
              </ul>
              <div class="text-right pt-4">
                <button
                  v-if="!ADDRESS || !USER_ACTIVE"
                  class="btn btn-dark btn-pricing"
                  @click="handleBuyClick">{{ $t('button.get') }}</button>
              </div>
            </div>
          </div>
          <div class="pricing-program">
            <simple-picture class="pricing-program-img" src="@/assets/images/pricing-business-program.png" alt="Business Program" x="2" />
            <div class="pricing-program-content max-w-[492px]">
              <div class="pricing-program-title">{{ $t('home.program.business.title') }}</div>
              <div class="pricing-program-subtitle">{{ $t('home.program.business.subtitle') }}</div>
              <ul class="pricing-program-features">
                <li>{{ $t('home.program.business.1') }}</li>
                <li>{{ $t('home.program.business.2') }}</li>
                <li>{{ $t('home.program.business.3') }}</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- <div class="pricing-card relative">
          <div class="pricing-program vip-program">
            <simple-picture class="pricing-program-img" src="@/assets/images/pricing-nft-program.png" alt="Classic Program" x="2" />
            <div class="pricing-program-content">
              <div class="pricing-program-title">{{ $t('home.program.nft.title') }}</div>
              <div class="pricing-program-subtitle">{{ $t('home.program.nft.subtitle') }}</div>
              <ul class="pricing-program-features">
                <li>{{ $t('home.program.nft.1') }}</li>
                <li>{{ $t('home.program.nft.2') }}</li>
                <li>{{ $t('home.program.nft.3') }}</li>
                <li>{{ $t('home.program.nft.4') }}</li>
                <li>{{ $t('home.program.nft.5') }}</li>
                <li>{{ $t('home.program.nft.6') }}</li>
                <li>{{ $t('home.program.nft.7') }}</li>
              </ul>
            </div>
          </div>
          <div class="ribbon-horizontal absolute right-[-8px] top-10 ">
            <span>VIP</span>
          </div>
        </div> -->
      </div>
    </section>

    <!-- <a id="how-it-works" name="how-it-works" class="anchor" href=""></a>
    <section class="bg-gray-200">
      <div class="container flex flex-col items-center py-24">
        <div class="h1">How Link Node Gift Card Works?</div>
        <div class="text-base">Make Every Online Payment with Cryptocurrency</div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-16 px-12 md:px-4 lg:px-20 mt-6 w-full">
          <div class="step-card">
            <span class="step-number">1</span>
            <div class="content-wrap">
              <div class="title">Purchase</div>
              <div class="content">Click "GET IT NOW" to purchase the prepaid card with mainstream cryptocurrency.</div>
            </div>
          </div>
          <div class="step-card">
            <span class="step-number">2</span>
            <div class="content-wrap">
              <div class="title">Top Up</div>
              <div class="content">Click "TOP UP" to top up your prepaid card with mainstream cryptocurrency.</div>
            </div>
          </div>
          <div class="step-card">
            <span class="step-number">3</span>
            <div class="content-wrap">
              <div class="title">Use It</div>
              <div class="content">Use the card anywhere online that accepts MasterCard.</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <a id="referral" name="referral" class="anchor" href=""></a>
    <section id="referral" class="section-bg">
      <div class="container flex flex-col items-center px-12 md:px-20 py-24">
        <img class="w-1/3" src="@/assets/images/referral@2x.png" alt="">
        <div class="h1">Referral</div>
        <div>
          <ol class="text-center">
            <li>1. Your friends can get 10% off for their new card.</li>
            <li>2. You can earn 10% rebate when they order the new card.</li>
            <li>3. You can earn 0.3% rebate when your friends Top Up.</li>
          </ol>
        </div>
        <div>
          <button
            type="button"
            class="
              btn-fixed-width
              transition ease-in-out delay-150 duration-150
              bg-primary
              hover:bg-primary-hover
              text-white
              font-bold
              py-2
              px-8
              rounded-lg
            "
          >
            Check My Referral
          </button>
        </div>
      </div>
    </section>

    <a id="q-and-a" name="q-and-a" class="anchor" href=""></a>
    <section>
      <div class="container flex flex-col items-center py-24">
        <div class="h1">Q&A</div>
        <div class="text-base">For more questions, please email us at contact@lngiftcard.com</div>
        <div class="px-12 md:px-20 mt-6 w-full">
          <div class="q-n-a">
            <div class="item">
              <div class="title">Which payment network and currency is used by the Card?</div>
              <div class="content">It is a Mastercard USD Prepaid Card for once or a few times use purposes.</div>
            </div>
            <div class="item">
              <div class="title">What is the cryptocurrency exchange rate for card top up?</div>
              <div class="content">The cryptocurrency exchange rate is the current market price, plus our service fees.</div>
            </div>
            <div class="item">
              <div class="title">What is the maximum top up amount per card?</div>
              <div class="content">The maximum top up amount is USD $350, and the maximum accumulated top up amount is USD $3,000. It means that the balance shown in the card can only be USD $350 at most. However, your pending balance will top up to your account daily if the balance is less than $350.</div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section id="support-tokens" class="bg-[#F5F5F5] py-20 lg:py-30 px-7">
      <div class="container">
        <div class="h2 mb-2 mx-auto">{{ $t('home.support.title') }}</div>
        <div class="text-xl mb-12">{{ $t('home.support.subtitle') }}</div>
        <div class="slider w-full">
          <div class="token" v-for="(token, i) in tokenList" :key="i" :class="[{ 'hidden': i >= 4 }, 'sm:block']">
            <img loading="lazy" :src="token.uri" :alt="token.key">
          </div>
        </div>
        <div class="text-xl mt-12">{{ $t('home.support.more') }}</div>
      </div>
    </section>

    <section id="contact-us" class="bg-white py-[120px] lg:py-60 px-7">
      <div class="container">
        <div class="h2 mb-2 mx-auto">{{ $t('home.contact.title') }}</div>
        <div class="text-xl font-semibold mb-12">{{ $t('home.contact.subtitle') }}</div>
        <!-- <a
          class="btn btn-fixed-width"
          href="mailto:service@paywide.io"
          target="_blank"
        >
          Email
        </a> -->
        <a class="btn btn-fixed-width" href="https://support.paywide.io/hc/en-us/requests/new" target="_blank" rel="noopener">
          {{ $t('button.contact-us') }}
        </a>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import SimplePicture from '@/components/SimplePicture.vue'
import { mapGetters } from 'vuex'
import PNG from '@/assets/images/currency'
import { TOKENS } from '@/constants'
import { flatten } from 'lodash-es'

export default {
  name: 'HomeView',
  components: {
    SimplePicture
  },
  data () {
    return {
      show: false,
      tokens: [
        'eth', 'usdc', 'dai', 'zrx', 'uni', 'usdt', 'link', 'wbtc'
      ],
      TOKENS
    }
  },
  computed: {
    ...mapGetters('WALLET', [
      'ADDRESS',
      'IS_LOGIN',
      'IS_CONNECTED'
    ]),
    ...mapGetters('CARD', [
      'USER_ACTIVE',
      'HAS_CARDS',
      'REFERRAL_CODE'
    ]),
    tokenList: function () {
      return this.tokens.map(el => {
        const allTokenArray = flatten(Object.values(TOKENS).map(el => el))
        const token = allTokenArray.find(token => token.symbol.toLowerCase() === el)
        const image = token.logoURI || PNG[`${el.toLowerCase()}.png`]
        return { key: el, uri: image }
      })
    }
  },
  mounted () {
    if (this.$route.query?.code?.length) {
      this.$store.dispatch('CARD/CACHE_REFERRAL_CODE', this.$route.query.code.trim())
    } else if (this.$route.query?.r?.length) {
      this.$store.dispatch('CARD/CACHE_REFERRAL_CODE', this.$route.query.r.trim())
    }
  },
  methods: {
    test () {
      // this.$store.dispatch('CARD/GET_CARD')
    },
    confirm () {
      // some code...
      this.show = false
    },
    cancel (close) {
      // some code...
      close()
    },
    async handleBuyClick () {
      if (!this.ADDRESS) {
        this.$store.dispatch('WALLET/OPEN_WEB3MODAL')
        return
      }
      if (this.USER_ACTIVE) {
        const { cards, orders } = await this.$store.dispatch('CARD/GET_CARDS')
        if (cards.length + orders.length > 0) {
          const path = this.localePath({ name: 'cardList' })
          this.$router.push(path)
        }
      } else {
        this.$store.dispatch('CARD/AUTO_ACTIVATE')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-kv {
  @apply bg-white min-h-[658px] lg:min-h-[762px];
  background-image: url("~@/assets/images/bg-kv-5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.h1 {
  @apply font-heading text-dark;
  @apply text-3xl lg:text-5xl font-semibold lg:font-bold lg:leading-[3.5rem];
  letter-spacing: -0.5px;
}
.h2 {
  @apply font-heading text-dark;
  @apply text-3xl lg:text-4xl font-bold lg:leading-11;
  @apply max-w-[800px];
  letter-spacing: -0.5px;
}

.feature-container {
  @apply md:flex justify-between mx-auto;
  @apply px-7 sm:px-0 py-[60px] lg:max-w-[1120px] w-full;
}
.feature-item {
  @apply flex md:block lg:flex text-left flex-wrap mb-10 last:mb-0 md:mb-0 mx-auto items-center md:items-start;
  .feature-img {
    @apply w-[60px] lg:w-[100px] mr-5;
  }
  .feature-content {
    @apply w-[220px] max-w-full;
    .feature-item-title {
      @apply font-heading text-xl lg:text-2xl text-dark;
    }
    .feature-item-text {
      @apply font-semibold;
    }
  }
}

.benefit-container {
  @apply overflow-x-auto px-7 sm:px-0;
  @apply flex justify-between mx-auto lg:max-w-[1120px] w-full;

}
.benefit-card {
  &:nth-child(n+2) {
    @apply ml-5;
  }
  @apply overflow-hidden bg-white;
  @apply text-left;
  @apply rounded-xl;
  @apply w-[340px] max-w-full min-w-[300px];
  .content-wrap {
    @apply p-10 pt-7;
  }
  .title {
    @apply font-heading text-dark;
    @apply text-2xl break-words mb-2;
  }
  .content {
    @apply text-base font-semibold break-words;
  }
}
::v-deep .merchant-item {
  @apply h-[60px];
}

// pricing
#pricing {
  background-image: url("~@/assets/images/bg-pricing.png");
  background-repeat: repeat;
  background-size: contain;
}
#pricing .h2 {
  @apply text-4xl mb-12 mx-auto;
}
.pricing-card {
  @apply rounded-xl bg-white mx-auto w-full max-w-[1120px] px-5 lg:px-10;
}
.pricing-program {
  @apply text-left py-5 lg:py-10 lg:flex w-80 max-w-full lg:w-full mx-auto;
  &:not(:first-child) {
    @apply border-t;
  }
  .pricing-program-img::v-deep {
    img {
      @apply mx-auto lg:mr-12 max-w-[320px] mb-2 lg:mb-0 w-full;
    }
  }
  &.vip-program .pricing-program-img::v-deep {
    img {
      @apply lg:max-w-[460px];
    }
  }
  .pricing-program-features {
    @apply max-w-[492px];
  }
  .pricing-program-title {
    @apply text-dark text-3xl font-semibold mb-1;
  }
  .pricing-program-subtitle {
    @apply text-dark text-base font-semibold mb-2;
  }
  .pricing-program-features {
    @apply list-disc pl-6 font-semibold;
  }
}
.btn-pricing {
  @apply min-w-[120px] rounded-lg;
}
.ribbon-horizontal {
  @apply w-[50px] lg:w-[90px] h-10 lg:h-12 bg-primary  py-2 lg:py-3 shadow-md;
  span {
    @apply text-white text-2xl leading-none font-semibold;
    @apply align-sub pr-1 lg:pr-[10px];
    vertical-align: sub;

  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
    width: 12px;
    border-top: 20px solid #DDC18C;
    border-bottom: 20px solid #DDC18C;
    border-left: 10px solid transparent;
    border-right: 2px solid #DDC18C;
    @media screen and (min-width: 1024px) {
      border-top: 24px solid #DDC18C;
      border-bottom: 24px solid #DDC18C;
    }
  }
}

.step-card {
  @apply relative rounded-xl bg-white w-full;
  @apply text-left;
  .step-number {
    @apply
      absolute -top-3 -left-3
      flex justify-center items-center
      rounded-full
      bg-primary
      w-16 h-16
      text-6xl
      font-extrabold;
  }
  .content-wrap {
    @apply p-6 pl-16;
  }  .title {
    @apply font-heading;
    @apply text-3xl text-primary font-medium;
  }
  .content {
    @apply text-base break-words;
  }
}
.section-bg {
  background-color: #FCF5EC;
}
.q-n-a {
  @apply bg-gray-100 rounded-md p-6;
  @apply text-left;
  .item:not(:last-child) {
    @apply border-b-2;
  }
  .item {
    @apply py-4;
  }
  .title {
    @apply font-heading;
    @apply text-primary text-base;
  }
  .content {
    @apply text-gray-800 text-base;
  }
}
.anchor {
  position: relative;
  display: block;
  top: -80px;
  visibility: hidden;
}
#contact-us {
  background-image: url('~@/assets/images/bg-map.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.slider {
  @apply flex justify-around;
  @apply max-w-[1120px] mx-auto px-5 lg:px-10;
  // animation: marquee 5s infinite linear;
  .token {
    @apply m-2 w-full max-w-[64px];
    @apply rounded-full overflow-hidden;
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
