<template>
  <div class="loading-bouncer">
    <div v-for="i of amount" :key="i" class="loading-bouncer-line"></div>
  </div>
</template>

<script>

export default {
  name: 'LoadingBouncer',
  data () {
    return {
      amount: 5
    }
  }
}
</script>

<style lang="scss" scoped>
// @import './styles.scss';
$animate-time: 2s;
$animate-delay: 0.2s;
$bounce-start: 5px;
$bounce-top: -10px;

.loading-bouncer {
  @for $i from 1 through 5 {
    .loading-bouncer-line:nth-child(#{$i}) {
      animation: bouncing $animate-time (($i - 1) * $animate-delay) ease-out infinite;
    }
  }
}

.loading-bouncer-line {
  display: inline-block;
  margin: 0 2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #c4c4c4;
  transform: translateY($bounce-start);
}

@keyframes bouncing {
  30% {
    transform: translateY($bounce-start);
  }
  50% {
    transform: translateY($bounce-top);
  }
  80% {
    transform: translateY($bounce-start);
  }
}
</style>
