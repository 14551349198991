<template>
  <picture>
    <source :srcset="srcset">
    <img loading="lazy" :class="imgClass" :src="imgSrc" :alt="alt">
  </picture>
</template>

<script>
export default {
  name: 'SimplePicture',
  props: {
    src: {
      type: String,
      required: true
    },
    x: {
      type: String,
      required: false,
      default: '1'
    },
    alt: {
      type: String,
      required: false,
      default: ''
    },
    imgClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    hasAlias () {
      return this.src.charAt(0) === '@' && this.src.charAt(1) === '/'
    },
    aliasSliced () {
      return this.hasAlias ? this.src.slice(2) : this.src
    },
    srcset () {
      const maxX = parseInt(this.x)
      const array = this.aliasSliced.split('.')
      const extension = array.pop()
      const name = array.join()
      let srcset = this.importImg(this.aliasSliced)
      for (let i = 2; i <= maxX; i++) {
        const img = this.importImg(`${name}@${i}x.${extension}`)
        srcset += `, ${img} ${i}x`
      }
      return srcset
    },
    imgSrc () {
      return this.importImg(this.aliasSliced)
    }
  },
  methods: {
    importImg (srcSliced) {
      return this.hasAlias ? require(`@/${srcSliced}`) : require(`${srcSliced}`)
    }
  }
}
</script>
