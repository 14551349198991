<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <simple-picture img-class="w-full mx-auto mt-[22px] mb-5 lg:mb-12" src="@/assets/images/modals/kv-top-up-received.png" alt="Top up received" x="2"/>
      <div class="modal__title heading text-4xl leading-11 text-dark">
        {{ $t('modal.top-up.complete.title') }}
      </div>
      <div class="text-primary mb-10 font-semibold">{{ $t('modal.top-up.complete.processing') }}</div>
      <section class="notice text-left font-semibold">
        <ul class="list-disc list-outside ml-5">
          <li>{{ $t('modal.top-up.complete.notice.0') }}</li>
          <li>{{ $t('modal.top-up.complete.notice.1') }}</li>
        </ul>
      </section>
    </div>
    <div class="modal__action">
      <button
        type="button"
        class="btn btn-fixed-width"
        @click="handleClick"
      >{{ $t('button.view-card') }}</button>
    </div>
    <button class="modal__close" @click="handleClose">
      <icon-close-popup />
    </button>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import SimplePicture from '@/components/SimplePicture.vue'

export default {
  components: { VueFinalModal, SimplePicture },
  name: 'TopUpComplete',
  inheritAttrs: false,
  computed: {
    CURRENT_ID () {
      return this.$store.getters['CARD/CURRENT_ID']
    }
  },
  methods: {
    handleClick () {
      this.goToCard()
    },
    handleClose () {
      this.goToCard()
    },
    goToCard () {
      this.$store.dispatch('MODAL/CLOSE')
      const path = this.localePath({ name: 'cardDetail', params: { id: this.CURRENT_ID } })
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
