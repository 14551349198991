<template>
  <vue-final-modal
    v-slot="{ params }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
    ref="alertModal"
  >
    <div class="modal__content p-11 text-left">
      <div v-if="params.title" class="modal__title text-dark mb-2"
        :class="{ 'text-[#DD0000]': params.type === 'error' }">
        {{ params.title }}
      </div>
      <div v-if="params.textHtml" class="font-semibold" v-html="params.textHtml"></div>
      <div v-else-if="params.text" class="font-semibold">
        <p>{{ params.text }}</p>
      </div>
    </div>
    <button v-if="!params.noClose" class="modal__close" @click="onClose">
      <icon-close-popup />
    </button>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
  components: { VueFinalModal },
  name: 'AlertTemplate',
  inheritAttrs: false,
  methods: {
    onClose () {
      const callback = this.$refs.alertModal.params.closeCallback
      if (callback) {
        callback()
      }
      this.$refs.alertModal.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
::v-deep .modal-content {
  max-width: 420px;
}
.modal__title {
  @apply text-2xl font-semibold;
}
</style>
