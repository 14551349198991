import Big from 'big.js'

Big.PE = 30
Big.NE = -19

function toCurrencyString (value, fillDecimalPlaceZero) {
  try {
    const parts = new Big(value).toString().split('.')
    parts[0] = parts[0].split('').reverse().join('').split(/(\d{3})/).join(',').split('').reverse().join('').replace(/([,]{2,})/g, ',').replace(/(^,|,$)/g, '')
    if (fillDecimalPlaceZero) {
      if (parts.length === 1) {
        parts.push('00')
      } else if (parts.length === 2 && parts[1].length === 1) {
        parts[1] += '0'
      }
    }
    return parts.join('.')
  } catch (e) {
    return '--'
  }
}

export {
  toCurrencyString
}
