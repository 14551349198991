<template>
  <div class="max-w-md lg:max-w-5xl mx-auto py-16">
    <!-- <h2 class="mb-5 pl-10 text-left text-2xl font-bold text-dark">
          {{ $t("profile.invitation-code") }}
        </h2> -->
    <!-- <p v-if="error" class="text-left pl-10">{{ error }}</p> -->
    <div v-if="code" class="form-wrap">
      <div class="lg:flex">
        <div class="max-w-full text-left flex flex-wrap items-center lg:w-1/2">
          <div class="flex items-baseline w-full text-sm mb-2 pl-5">
            <h2 class="text-left text-xl font-bold text-dark mr-4">
              {{ $t("profile.invitation-code") }}
            </h2>
            <div v-if="showAvailableCount" class="font-medium">
              {{ $t("profile.available") }}: {{ availableCount }}
            </div>
          </div>

          <div
            class="mb-2 border-2 border-[#e6e6e6] h-[60px] rounded-xl w-full max-w-lg bg-white px-5 py-3 flex items-center"
            :class="{ '!border-primary': availableCount }"
          >
            <div
              class="font-bold text-2xl"
              :class="{ 'text-primary': availableCount }"
            >
              {{ code }}
            </div>
            <button
              v-if="availableCount"
              class="ml-auto"
              :class="{ '!text-primary': availableCount }"
              v-clipboard:copy="code"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
              type="button"
            >
              <font-awesome-icon v-if="copied" :icon="['fas', 'check']" />
              <font-awesome-icon v-else :icon="['far', 'copy']" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserInvitation } from '@/utils/api/card'

export default {
  name: 'ProfilePage',
  data () {
    return {
      loading: true,
      error: '',
      code: '',
      availableCount: 0,
      copied: false
    }
  },
  computed: {
    ...mapGetters('WALLET', ['ADDRESS']),
    displayedAddress () {
      return this.ADDRESS.replace(/(.{6}).+(.{6})/, (match, p1, p2) => {
        return `${p1}...${p2}`
      })
    },
    available () {
      return this.availableCount !== 0
    },
    showAvailableCount () {
      return this.availableCount !== -1
    }
  },
  mounted () {
    // get invitation code
    this.getUserInvitation()
  },
  methods: {
    async getUserInvitation () {
      try {
        this.loading = true
        const { code, availableCount } = await getUserInvitation()
        this.code = code
        this.availableCount = availableCount
      } catch (e) {
        this.error = e.response.data.message
      } finally {
        this.loading = false
      }
    },
    onCopySuccess () {
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 2e3)
    },
    onCopyError () {
      alert('Failed to copy invitation code')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-wrap {
  @apply bg-[#F4F4F4] rounded-2xl lg:rounded-[32px] py-[30px] px-5 lg:px-10;
  // @apply grid grid-cols-12 gap-2 md:gap-4;
  @apply justify-center items-center mb-2;
  .form-flex {
    @apply flex flex-wrap gap-x-5;
  }
  // grid-template-columns: 3fr 2fr 4fr;
  .amount-field {
    @apply sm:flex flex-col space-y-1;
  }
  .total-amount-field {
    @apply max-w-full;
    .total-amount {
      @apply text-dark text-3xl lg:text-5xl lg:leading-[60px] font-heading flex relative;
    }
    .total-amount-value {
      @apply relative pl-5 lg:pl-0 w-0 grow truncate;
    }
  }
  .input-field {
    @apply relative;
    @apply block text-left;
    @apply bg-[#E6E6E6];
    @apply border-2 border-[#E6E6E6];
    @apply rounded-xl h-[60px] px-4 max-w-full;
    input {
      @apply bg-transparent;
      @apply focus:outline-none;
      @apply font-heading text-left text-dark text-3xl leading-[38px];
      @apply py-[9px] max-w-full;
    }
    span::after {
      @apply leading-[56px] font-semibold;
      content: "%";
      position: absolute;
      right: 1rem;
    }
    &.error {
      @apply border-2 border-red-400;
    }
  }
}
</style>
