<template>
  <div>
    <div v-for="locale in locales" :key="locale">
      <button @click="handleChangeLang(locale)">{{locale}}</button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      locales: ['en', 'zh-tw', 'zh-cn']
    }
  },
  methods: {
    handleChangeLang (locale) {
      // this.$i18n.locale = locale
      this.$store.dispatch('setLang', locale)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
