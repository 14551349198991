<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <simple-picture img-class="w-full mx-auto pb-8 mt-[130px]" src="@/assets/images/modals/kv-top-up-failed.png" alt="" x="2" />
      <div class="modal__title heading text-dark text-4xl leading-11 mb-8 lg:mb-10">
        {{ $t('modal.top-up.fail.title') }}
      </div>
      <div class="font-semibold mb-[122px]">
        <p>{{ $t('modal.top-up.fail.content') }}</p>
      </div>
    </div>
    <button class="modal__close" @click="handleClick">
      <icon-close-popup />
    </button>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import SimplePicture from '@/components/SimplePicture.vue'

export default {
  components: { VueFinalModal, SimplePicture },
  name: 'TopUpFail',
  inheritAttrs: false,
  methods: {
    handleClick () {
      // console.log('handleClick')
      this.$store.dispatch('MODAL/CLOSE')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
