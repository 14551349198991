<template>
  <div class="card-item" @click="goToCardDetail">
    <div
      class="
        grid
        grid-cols-[80px_auto]
        lg:grid-cols-[320px_auto_48px]
        gap-x-5
        lg:gap-x-[42px]
        gap-y-4
        lg:gap-y-5
      "
    >
      <div class="w-20 lg:w-80 lg:row-span-2">
        <simple-picture
          class="w-full"
          :src="programImage"
          :alt="$t('card.list.item.alt.card')"
          x="2"
        />
      </div>
      <div
        class="
          relative
          font-semibold
          text-left
          col-span-2
          lg:col-span-1 lg:row-span-2 lg:col-start-2
        "
      >
        <div class="lg:absolute lg:top-1/2 lg:-translate-y-1/2">
          <div class="text-dark text-xl pt-5 lg:pt-0">
            {{ $t('card.list.item.how-to-activate') }}
          </div>
          <div>{{ $t('card.list.item.top-up-first') }}</div>
        </div>
      </div>
      <div class="relative hidden lg:block col-start-3 row-start-1 row-end-3">
        <img
          class="absolute top-1/2 -translate-y-1/2"
          src="@/assets/images/icons/angle-right.svg"
          :alt="$t('card.list.item.alt.detail')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardItemNotActivated',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  computed: {
    programImage () {
      switch (this.card.program) {
        case 'business':
          return '@/assets/images/pricing-business-program.png'
        case 'classic':
        default:
          return '@/assets/images/pricing-classic-program.png'
      }
    }
  },
  methods: {
    goToCardDetail () {
      const path = this.localePath({ name: 'cardDetail', params: { id: this.card.id } })
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-item {
  @apply rounded-2xl lg:rounded-[32px] p-5 lg:p-10 min-h-[226px] lg:min-h-[282px] mb-4 lg:mb-5;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  &:hover {
    @apply cursor-pointer scale-[1.02] border-primary border-2;
  }
}
</style>
