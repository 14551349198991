const networks = [
  {
    chainId: '0x3',
    chainName: 'Ropsten',
    rpcUrls: ['https://ropsten.infura.io/v3/', 'https://ropsten.infura.io/v3/b61213d57bc749ebbdcc2e5a7f1d5473']
  },
  {
    chainId: '0x5',
    chainName: 'Goerli',
    rpcUrls: ['https://goerli.infura.io/v3/', 'https://goerli.infura.io/v3/b61213d57bc749ebbdcc2e5a7f1d5473']
  },
  {
    chainId: '0x38',
    chainName: 'BSC Mainnet',
    rpcUrls: ['https://bsc-dataseed1.binance.org', 'https://bsc-dataseed.binance.org', 'https://binance.nodereal.io']
  },
  {
    chainId: '0x61',
    chainName: 'BSC Testnet',
    rpcUrls: ['https://data-seed-prebsc-2-s3.binance.org:8545', 'https://data-seed-prebsc-1-s2.binance.org:8545', 'https://data-seed-prebsc-2-s2.binance.org:8545']
  },
  {
    chainId: '0x13881',
    chainName: 'Polygon Mumbai',
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com', 'https://rpc-mumbai.maticvigil.com']
  },
  {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    rpcUrls: ['https://polygon-rpc.com', 'https://rpc-mainnet.matic.quiknode.pro', 'https://rpc.ankr.com/polygon']
  },
  {
    chainId: '0xa86a', // 43114
    chainName: 'Avalanche C-Chain',
    rpcUrls: ['https://rpc.ankr.com/avalanche', 'https://api.avax.network/ext/bc/C/rpc']
  },
  {
    chainId: '0xa869',
    chainName: 'Avalanche Fuji Testnet',
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc']
  },
  {
    chainId: '0xaa36a7',
    chainName: 'Sepolia',
    rpcUrls: ['https://sepolia.infura.io/v3/', 'https://sepolia.infura.io/v3/b61213d57bc749ebbdcc2e5a7f1d5473']
  }
]

export default networks
