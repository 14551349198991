import { render, staticRenderFns } from "./TopUpComplete.vue?vue&type=template&id=3edcc982&scoped=true"
import script from "./TopUpComplete.vue?vue&type=script&lang=js"
export * from "./TopUpComplete.vue?vue&type=script&lang=js"
import style0 from "./TopUpComplete.vue?vue&type=style&index=0&id=3edcc982&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edcc982",
  null
  
)

export default component.exports