<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(243, 244, 246, 1)"
  >
    <div v-if="data.length === 0" class="min-h-[300px] flex justify-center items-center font-semibold">
      {{ $t('card.no-data') }}
    </div>
    <div v-else class="table-wrap min-h-[300px]">
      <el-table
        :data="data"
        style="width: 100%">
        <!-- <slot></slot> -->
        <el-table-column
          prop="at"
          label="Date"
          align="left"
          width="160">
        </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          align="left"
          width="120"
          el-table-column>
          <template slot-scope="scope">
            <span>{{ rippedId(scope.row.id) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="original.amount"
          label="Amount"
          align="left"
          width="160">
        </el-table-column>
        <el-table-column
          prop="original.currency"
          label="Currency"
          align="left"
          min-width="160">
          <template slot-scope="scope">
            {{ scope.row.original.currency.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="Description"
          align="left"
          min-width="200">
        </el-table-column>
        <el-table-column
          prop="type"
          label="Type"
          align="left"
          min-width="160">
        </el-table-column>
      </el-table>
      <el-pagination
        :page-size="limit"
        :pager-count="5"
        layout="prev, pager, next"
        :total="total"
        :hide-on-single-page="true"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryPendingTransactionHistory
} from '@/utils/api/card'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      data: [],
      skip: 0,
      limit: 10,
      total: 0,
      loading: true
    }
  },
  computed: {
    ...mapGetters('WALLET', [
      'ADDRESS'
    ]),
    ...mapGetters('CARD', [
      'CARD_STATUS',
      'CURRENT_ID'
    ])
  },
  mounted () {
    this.queryHistory()
  },
  methods: {
    async queryHistory () {
      if (this.CARD_STATUS !== 'active') {
        this.loading = false
        return
      }
      this.loading = true
      const { limit, skip } = this
      const params = {
        id: this.CURRENT_ID,
        limit,
        skip
      }
      const response = await queryPendingTransactionHistory(params)
      const { items, total } = response
      this.total = total
      // this.total = 1
      this.data = items
      // this.data = [{
      //   at: '2022-10-10',
      //   original: {
      //     amount: '200',
      //     currency: 'usdc'
      //   },
      //   type: 'free',
      //   description: 'PCHome'
      // }]
      this.loading = false
    },
    handlePageChange (page) {
      this.skip = (page - 1) * this.limit
      this.queryHistory()
    },
    rippedId (value) {
      const charLeft = 6
      return typeof value === 'string' ? value.substring(value.length - charLeft) : value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/transactions.scss';
</style>
