<template>
  <div class="dropdown-item" @click="handleClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  //
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-item {
  @apply whitespace-nowrap;
  @apply flex space-x-2 justify-center items-center;
  @apply py-2 px-6;
  @apply cursor-pointer;
  @apply hover:bg-[#F4F4F4];
}
</style>
