const localeFiles = require.context('../locales/', false, /\.json$/)
const supportedLocales = []
localeFiles.keys().forEach((key) => {
  if (key === './index.js') return
  supportedLocales.push(key.replace(/(\.\/|\.json)/g, ''))
})

export const addLocaleToPath = (currentLang, path) => {
  if (supportedLocales.includes(currentLang)) {
    return `/${currentLang}${path}`
  }
  return path
}
export const globalMixin = {
  data () {
    return {
      supportedLocales
    }
  },
  computed: {
    isDev: function () {
      return process.env.VUE_APP_ENV === 'development'
    },
    isProd: function () {
      return process.env.VUE_APP_ENV === 'production'
    }
  },
  methods: {
    localePath (path) {
      // console.log('supportedLocales', supportedLocales)
      const currentRoute = { ...this.$route }
      const lang = currentRoute.params.lang
      // console.log('currentLocale', currentRoute)
      // console.log('params', currentRoute.params)
      // console.log('lang', currentRoute.params.lang)
      if (typeof path === 'string') {
        return lang ? `/${lang}${path}` : path
      }
      // 如果 path 是 obj
      if (path.params) {
        path.params.lang = currentRoute.params.lang
      } else {
        path.params = {
          lang: currentRoute.params.lang
        }
      }
      return path
    }
  }
}
