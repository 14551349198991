<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <div v-if="codeLoading || code" class="">
        <p class="font-semibold text-left">
          {{ description }}
        </p>
        <div class="flex items-center justify-center">
          <div v-if="codeLoading" class="py-[100px] text-center">
            <loading-bouncer />
          </div>
          <div v-else-if="error" class="font-semibold">{{ error }}</div>
          <div v-else-if="code" class="w-full">
            <div class="heading text-dark text-6xl py-[22px] leading-[1.2]">{{ code }}</div>
            <div v-if="has3DSEntry()">
              <div class="threeDSInfo">
                <div>{{ $t('modal.3ds.merchant') }}</div>
                <div>{{ threeDSDetail.merchantName }}</div>
              </div>
              <div class="threeDSInfo">
                <div>{{ $t('modal.3ds.currency') }}</div>
                <div>{{ threeDSDetail.currency.toUpperCase() }}</div>
              </div>
              <div class="threeDSInfo">
                <div>{{ $t('modal.3ds.amount') }}</div>
                <div>{{ threeDSDetail.amount }}</div>
              </div>
            </div>
          </div>
        </div>
        <p class="pt-4 text-xs leading-5">
          {{ $t('modal.3ds.reminder') }}
        </p>
      </div>
      <div v-else>
        <div class="text-dark text-2xl font-semibold max-w-[300px] mx-auto mb-10">
          {{ noDataDescription }}
        </div>
        <div class="px-4 text-center">
          <simple-picture img-class="w-full mx-auto" src="@/assets/images/modals/kv-no-3ds.png" alt="No 3DS-related requests" x="2" />
        </div>
      </div>
    </div>
    <div class="modal__action">
      <button
        type="button"
        class="btn btn-fixed-width"
        :class="{ disabled: confirmDisabled }"
        @click="close"
      >{{ $t('button.confirm') }}</button>
    </div>
    <!-- <button class="modal__close" @click="close">
      <font-awesome-icon :icon="['fas', 'xmark']" class="text-gray-500" size="2x" />
    </button> -->
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import SimplePicture from '@/components/SimplePicture.vue'
import LoadingBouncer from '@/components/LoadingBouncer.vue'
import { get3DS } from '@/utils/api/card'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash-es'

export default {
  components: { VueFinalModal, SimplePicture, LoadingBouncer },
  name: 'Card3DSecure',
  inheritAttrs: false,
  data () {
    return {
      codeLoading: true,
      code: '',
      error: '',
      threeDSDetail: {}
    }
  },
  computed: {
    confirmDisabled () {
      return this.codeLoading
    },
    ...mapGetters('WALLET', [
      'ADDRESS'
    ]),
    ...mapGetters('CARD', [
      'CURRENT_ID',
      'CARD_OTP_ONLY_CODE'
    ]),
    description () {
      if (this.CARD_OTP_ONLY_CODE) {
        return this.$t('modal.otp.description')
      } else {
        return this.$t('modal.3ds.description')
      }
    },
    noDataDescription () {
      if (this.CARD_OTP_ONLY_CODE) {
        return this.$t('modal.otp.no-data')
      } else {
        return this.$t('modal.3ds.no-data')
      }
    }
  },
  created () {
    // setTimeout(() => {
    //   this.code = '888888'
    //   // this.error = 'Error'
    //   this.codeLoading = false
    // }, 2e3)
    this.get3DSCode()
  },
  methods: {
    has3DSEntry () {
      // console.log('has3DSEntry')
      return !isEmpty(this.threeDSDetail) && !this.CARD_OTP_ONLY_CODE
    },
    handleClick () {
      // this.$store.dispatch('MODAL/CLOSE')
    },
    async get3DSCode () {
      this.codeLoading = true
      try {
        const response = await get3DS(this.CURRENT_ID)
        // const response = { otp: '09323', currency: 'usd', amount: '12.3', merchantName: 'PCHome' }
        // const response = 'OK'
        if (response === 'OK') {
          this.code = false
          return
        }
        const { otp } = response
        this.code = otp
        this.threeDSDetail = response
        // this.amount = amount
        // this.merchantName = merchantName
        return
      } catch (e) {
        console.warn('Error: ', e)
      } finally {
        this.codeLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.threeDSInfo {
  @apply flex justify-between items-center;
  @apply text-dark bg-[#F4F4F4];
  @apply px-5 py-2;
  &:not(:last-child) {
    @apply mb-1;
  }
  &:first-of-type  {
    @apply rounded-t-xl;
  }
  &:last-of-type {
    @apply rounded-b-xl;
  }
}
</style>
