<template>
  <div v-if="!hide">
    <div
      class="
        fixed
        top-0
        right-0
        bottom-0
        left-0
        z-[1000]
        bg-black/50
        flex
        justify-center
        items-center
      "
      :class="{hidden: !showMobile}"
    >
      <div class="">
        <div class="relative event-container rounded-2xl bg-white p-3 pt-12">
          <button class="absolute top-0 right-0" type="button" @click="closeMobileModal">
            <icon-close-popup />
          </button>
          <simple-picture img-class="w-[256px]" src="@/assets/images/event/2023-pw.png" :alt="imgAlt" x="1" />
        </div>
      </div>
    </div>

    <div
      class="
        hidden
        md:block
        fixed
        top-[100px]
        right-5
        z-40
      "
      :class="{'!hidden': !showDesktopButton}"
    >
      <button
        v-if="!showDesktopContent"
        class="text-dark bg-white rounded-2xl pl-5
          shadow-[0_0_20px_rgba(0,0,0,0.1)] hover:shadow-[0_0_20px_rgba(0,0,0,0.2)]
        "
        type="button" @click="expandDesktop"
      >
        <span class="leading-[48px] font-semibold">{{ $t('event.latest') }}</span>
        <img class="inline-block align-bottom" src="@/assets/images/icons/activity-normal.svg" alt="activity">
      </button>
      <div
        v-else
        class="relative event-container rounded-2xl bg-white p-3 pt-12 shadow-[0_0_20px_rgba(0,0,0,0.1)]"
      >
        <button class="absolute top-0 right-0" type="button" @click="collapseDesktop">
          <img class="inline-block align-bottom" src="@/assets/images/icons/up-arrow.svg" alt="collapse">
        </button>
        <simple-picture img-class="w-[256px]" src="@/assets/images/event/2023-pw.png" :alt="imgAlt" x="1" />
      </div>
    </div>
  </div>
</template>

<script>
import SimplePicture from '@/components/SimplePicture.vue'
export default {
  name: 'EventPopper',
  components: { SimplePicture },
  props: {
    hideEvent: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      hide: false,
      isMobile: false,
      showMobile: false,
      showDesktopButton: false,
      showDesktopContent: true,
      imgAlt: 'Invitation code: PWTGM2023 Limited time only'
    }
  },
  mounted () {
    if (window.screen.width < 768) {
      this.isMobile = true
    }
    if (this.isMobile) {
      this.initMobileModal()
    } else {
      this.initDesktopButton()
    }
  },
  watch: {
    hideEvent: function (value) {
      if (value) {
        this.hide = true
        if (this.isMobile) {
          this.closeMobileModal()
        } else {
          this.collapseDesktop()
        }
      }
    }
  },
  methods: {
    initMobileModal () {
      document.body.style.overflow = 'hidden'
      this.showMobile = true
    },
    closeMobileModal () {
      document.body.style.overflow = null
      this.showMobile = false
    },
    initDesktopButton () {
      this.showDesktopButton = true
    },
    expandDesktop () {
      this.showDesktopContent = true
    },
    collapseDesktop () {
      this.showDesktopContent = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
