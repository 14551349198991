<template>
  <!-- <div class="dropdown-menu-wrap"> -->
    <div class="dropdown-menu">
      <slot />
    </div>
  <!-- </div> -->
</template>
<script>
export default {
  //
}
</script>

<style lang="scss" scoped>
.dropdown-enter-active, .dropdown-leave-active {
  transition: all .3s ease-in-out;
}
.dropdown-enter, .dropdown-leave-to {
  transform: scaleY(0);
}
// .dropdown-menu-wrap {
//   @apply absolute;
//   @apply top-[100%];
//   transform-origin: center top;
// }
.dropdown-menu {
  @apply relative overflow-hidden py-2;
  @apply bg-white;
  @apply rounded-b-lg;
  @apply left-0;
  @apply z-50;
  top: calc(16px + 5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform-origin: center top;
}
</style>
