<template>
  <div class="container">
    <div class="max-w-md lg:max-w-5xl mx-auto px-7 lg:px-11 py-20">
      <div class="card-info mb-8 lg:mb-9">
        <div class="text-primary font-semibold">{{ $t('top-up.card-number') }}</div>
        <div class="text-dark heading text-3xl lg:text-4xl lg:leading-[44px]">{{ SEPARATED_CARD_NUMBER }}</div>
      </div>
      <section class="form flex flex-col pb-8">
        <div>
          <div class="form-wrap">
            <div class="lg:flex">

              <div class="max-w-full text-left flex flex-wrap items-center lg:w-1/2">
                <div class="w-full text-sm mb-2 pl-5 font-semibold">{{ $t('top-up.top-up-amount') }}</div>
                <div class="currency-selector w-full lg:w-auto mb-4 lg:mb-0 lg:order-2">
                  <CurrencySelect class="w-full lg:w-40 h-12 max-w-full" v-model="selectedCurrency" v-on:valueSelect="onValueSelect" :disabled="!exRate||!balance" />
                  <!-- <div>allowance: {{ allowance }}
                    <a href="javascript:;"
                      v-if="allowance !== 'N/A'"
                      class="text-link" @click="editAllowace">edit</a>
                  </div> -->
                </div>
                <div class="max-w-full lg:w-[225px] lg:mr-5">
                  <div :class="[ 'input-field', { error: amountError && exRate !== '-1' } ]" >
                    <input v-model="amount" type="text">
                  </div>
                </div>
                <div v-if="balance" class="w-full text-sm pl-5 min-h-[1.25rem] lg:order-4">
                  <div>
                    <label>{{ $t('top-up.wallet-balance') }}: </label>
                    <span>{{ balance }}</span>
                  </div>
                </div>
                <div class="w-full text-sm pl-5 min-h-[1.25rem] lg:order-4">
                  <div>
                    <span v-if="amountError" class="amount-warning">{{ amountError }}</span>
                    <span v-else-if="amountExceed">{{ amountExceed }}</span>
                    <span v-else-if="isNotOrderCurrency() && exRate" class="whitespace-nowrap">1 {{ selectedCurrency }} ≈ {{ toCurrencyString(Big(exRate).toFixed(2, 0)) }} USD</span>
                  </div>
                </div>
              </div>

              <div class="relative -mx-5 mb-3 h-8 lg:hidden">
                <div class="absolute border border-white top-1/2 w-full"></div>
                <img class="absolute top-0 left-1/2 -translate-x-1/2 bg-[#F4F4F4]"
                  src="@/assets/images/icons/equal-arrow-down.svg" alt="approximately equal to">
              </div>
              <div class="hidden relative lg:block mx-4 h-[60px] mt-7">
                <img class="relative top-1/2 -translate-y-1/2"
                  src="@/assets/images/icons/equal-arrow.svg" alt="approximately equal to">
              </div>

              <div class="total-amount-field self-start text-left whitespace-nowrap lg:w-[45%]">
                <div class="text-sm font-semibold lg:mb-2 pl-5 lg:pl-0">{{ $t('top-up.total-amount') }}</div>
                <div class="total-amount">
                  <span class="total-amount-value">
                    <template v-if="exRate">{{ toCurrencyString(calculateTotalAmount().toFixed(2, 1), true) }}</template>
                    <template v-else>--</template>
                  </span>
                  <span class="ml-4 mr-5 lg:mr-0"> {{ displayedCurrency.toUpperCase() }}</span>
                </div>
                <div class="lg:min-h-[1.25rem]">
                </div>
              </div>

            </div>
            <!-- <div class="form-flex"> -->
              <div v-if="isNotOrderCurrency()" class="slippage-warp max-w-full text-left mt-3 lg:mt-4">
                <div class="slippage lg:flex items-center justify-start">
                  <div class="w-full lg:w-auto text-sm mb-2 pl-5 font-semibold lg:mr-4">{{ $t('top-up.slippage') }}
                    <span class="inline-block align-bottom">
                      <el-popover popper-class="invoice-slippage-popover" width="200" trigger="hover" :content="$t('top-up.slippage-note')" placement="bottom">
                        <img class="w-5 h-5 inline-block" src="@/assets/images/icons/exclamation.svg" alt="note" slot="reference">
                      </el-popover>
                    </span>
                  </div>
                  <div class="input-field" :class="{ error: slippageError }">
                    <input class="w-full pr-8" v-model="slippage" type="number" min="0.1" max="50.0" step="0.01" @input="slippageValid">
                    <span></span>
                  </div>

                </div>
                <div v-if="slippageError" class="slippage-warning pl-5">
                  <span>{{ slippageError }}</span>
                </div>
              </div>
            <!-- </div> -->
          </div>
          <div class="notice text-left mb-10 lg:mb-20 lg:px-10">
            <ul class="list-disc list-outside ml-8 font-semibold">
              <!-- <li v-if="isUniswap">{{ $t('top-up.notice.0') }} Uniswap</li> -->
              <!-- <li v-else-if="isPancakeswap">Trade rate source: PancakeSwap</li> -->
              <li>{{ $t('top-up.notice.1') }}
                <span v-if="originalFeeRate === feeRate"> {{ toPercentage(feeRate) }}</span>
                <span v-else> <span class="line-through">{{ toPercentage(originalFeeRate) }}</span> <span class="text-danger">{{ toPercentage(feeRate) }}</span></span>
              </li>
              <li>{{ $t('top-up.notice.2') }}</li>
              <!-- <li v-if="CARD_NEW">The first-time minimum top up amount is USD 1,000.</li> -->
              <!-- <li if="minTopup && maxTopup">{{ $t('top-up.notice.3') }} {{ $t('top-up.notice.min') }} USD {{ toCurrencyString(minTopup) }}, {{ $t('top-up.notice.max') }} USD {{ toCurrencyString(maxTopup) }}</li> -->
              <li v-if="isNotOrderCurrency()">{{ $t('top-up.uniswap-disclaimer') }}</li>
            </ul>
          </div>
        </div>
        <div class="actions">
          <!-- <div class="agreement inline-block" @click="agreement = !agreement">
            <font-awesome-icon v-if="agreement" :icon="['fas', 'square-check']" size="2x" class="text-primary"/>
            <font-awesome-icon v-else :icon="['far', 'square']" size="2x" class="text-primary"/>
            <span>agreement.</span>
          </div> -->
          <div>
            <button
              id="top-up-confirm-btn"
              @click="handleConfirmClick" :class="['btn', 'btn-fixed-width', { 'disabled': !formValid || !isCanTopup }]">{{ $t('button.confirm') }}</button>
            <div class="text-sm text-red-400 text-center pt-2" v-if="!checkChainValid()">{{ $t('top-up.network-warning') }}</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CurrencySelect from '@/components/CurrencySelect'
import { getTopupInfo, editOrder } from '@/utils/api/card'
import { mapGetters } from 'vuex'
import Big from 'big.js'
import { debounce } from 'lodash-es'
import { TOKENS } from '@/constants'
import { toCurrencyString } from '@/utils/currency-helper'
import Log from '@/utils/log'
import router from '@/router'
import { addLocaleToPath } from '@/utils/mixins'
const supportedChain = JSON.parse(process.env.VUE_APP_SUPPORTED_CHAIN)

export default {
  name: 'TopUpView',
  components: {
    CurrencySelect
  },
  data () {
    return {
      agreement: true,
      orderId: '',
      topupCurrency: '',
      address: '',
      currency: '',
      feeRate: '',
      original: {}, // originalFeeRate
      amount: '',
      selectedCurrency: '',
      exRate: null,
      balance: '',
      slippage: 0.1,
      slippageError: '',
      lower: '',
      validatorInit: false,
      supportedChain
      // allowance: 'N/A'
    }
  },
  computed: {
    ...mapGetters('WALLET', [
      'ADDRESS',
      'CHAIN_ID',
      'CHAIN_CURRENCY'
    ]),
    ...mapGetters('CARD', [
      'CARD_NEW',
      'CARD_ACTIVE',
      'CARD_EXPIRED',
      'SEPARATED_CARD_NUMBER',
      'CARD_DETAIL',
      'CURRENT_ID'
    ]),
    currencyConfig () {
      if (this.displayedCurrency) {
        return TOKENS[this.CHAIN_ID].find(el => el.symbol.toLowerCase() === this.displayedCurrency.toLowerCase())
      }
      return {}
    },
    minTopup () {
      if (this.lower && this.currencyConfig.baseUnit) {
        return Big(this.lower).div(this.currencyConfig.baseUnit)
      }
      return ''
    },
    maxTopup () {
      const { balance, balanceUpper, pendingTopup } = this.CARD_DETAIL
      try {
        return new Big(balanceUpper).minus(balance).minus(pendingTopup)
      } catch (e) {
        Log('calculate maxTopup error:')
        Log(e)
        return ''
      }
      // if (this.lower && this.currencyConfig.baseUnit) {
      //   return Big(this.upper).div(this.currencyConfig.baseUnit)
      // }
      // return ''
    },
    amountError () {
      let amountError = ''
      if (this.exRate === '-1') {
        return this.$t('top-up.rate-error.text')
      }
      if (!this.currency || this.amount === '') {
        return ''
      }
      try {
        const inputAmount = new Big(this.amount)
        if (this.balance && inputAmount.gt(this.balance)) {
          // return 'Insufficient balance.'
          return this.$t('top-up.error-msg.insufficient-balance')
        }
        const amount = this.calculateTotalAmount()
        const minTopup = Big(this.lower).div(this.currencyConfig.baseUnit)
        // const maxTopup = Big(this.upper).div(this.currencyConfig.baseUnit)
        if (amount.lt(minTopup)) {
          amountError = `${this.$t('top-up.error-msg.min')} ${toCurrencyString(minTopup)} USD`
        }
        // else if (amount.gt(maxTopup)) {
        //   amountError = `Max. USD ${toCurrencyString(maxTopup)}`
        // }
      } catch (e) {
        amountError = this.$t('top-up.error-msg.amount-invalid')
      }
      return amountError
    },
    amountExceed () {
      if (!this.currency || this.amount === '' || this.maxTopup === '') {
        return ''
      }
      const amount = this.calculateTotalAmount()
      if (amount.gt(this.maxTopup)) {
        return this.$t('top-up.error-msg.exceed-upper')
      }
      return ''
    },
    amountValid () {
      return this.amount && !this.amountError
    },
    formValid () {
      let slippageValid = true
      if (this.isNotOrderCurrency()) {
        slippageValid = this.slippageValid()
      }
      return this.exRate && this.agreement && this.amountValid && slippageValid
    },
    isCanTopup () {
      return this.CARD_NEW || this.CARD_ACTIVE
    },
    isUniswap () {
      return ['1', '3', '5'].includes(this.CHAIN_ID?.toString())
    },
    isPancakeswap () {
      return ['56', '97'].includes(this.CHAIN_ID?.toString())
    },
    displayedCurrency () {
      const currency = this.currency
      // if (currency.toLowerCase() === 'usdt-avaxc-erc20' || currency.toLowerCase() === 'usdt-matic-erc20') {
      //   currency = 'usdt'
      // }
      return currency.split('-')[0]
    },
    originalFeeRate () {
      return this.original.feeRate || this.feeRate
    }
  },
  watch: {
    selectedCurrency (val) {
      if (!this.currency) return
      const amount = this.amount || '1'
      this.getExRate(amount)
      this.getBalance()
      // this.getAllowance()
    },
    CHAIN_ID (val) {
      if (!val) {
        return
      }
      if (this.CARD_ACTIVE) {
        this.getTopupInfo()
      } else if (this.CARD_NEW) {
        this.getOrderInfo()
      }
    },
    amount (val, old) {
      if (!this.currency) return
      if (val && this.validAmount(val)) {
        if (old && this.validAmount(val) && Big(val).eq(old)) {
          return
        }
        this.debouncedGetExRate(val)
      }
    },
    slippage (val) {
      if (!this.currency) return
      if (val && this.validAmount(val)) {
        const amount = this.amount || '1'
        this.debouncedGetExRate(amount)
      }
    }
  },
  mounted () {
    this.debouncedGetExRate = debounce(this.getExRate, 500)
    if ((!this.CARD_ACTIVE && !this.CARD_NEW) || this.CARD_EXPIRED) {
      const path = this.localePath({ name: 'cardDetail', params: { id: this.CURRENT_ID } })
      this.$router.push(path)
    } else if (this.CARD_ACTIVE) {
      this.getTopupInfo()
    } else if (this.CARD_NEW) {
      this.getOrderInfo()
    }
  },
  methods: {
    Big: Big,
    toPercentage (value) {
      try {
        return Big(value).times(100) + '%'
      } catch (e) {
        return '-- %'
      }
    },
    onValueSelect (value) {
      this.selectedCurrency = value
    },
    checkChainValid () {
      let valid = false
      const currentChainIdString = this.CHAIN_ID?.toString()
      const supportedChainIds = Object.keys(supportedChain).map(k => k)
      if (supportedChainIds.includes(currentChainIdString)) {
        valid = true
      }
      return valid
    },
    async getBalance () {
      this.balance = ''
      const balance = await this.$store.dispatch('WALLET/GET_TOKEN_BALANCE', { token: this.selectedCurrency.toLowerCase() })
      this.balance = balance
    },
    // first top up
    async getOrderInfo () {
      const chainCurrency = this.CHAIN_CURRENCY
      const { id, address, currency, topup } = await editOrder(this.CURRENT_ID, chainCurrency)
      this.orderId = id
      this.address = address
      this.currency = currency
      this.original = topup.original
      this.feeRate = topup.feeRate
      this.lower = topup.lower
      this.selectedCurrency = this.displayedCurrency.toUpperCase()
      this.getExRate()
      this.getBalance()
    },
    async getTopupInfo () {
      const chainCurrency = this.CHAIN_CURRENCY
      const id = this.CURRENT_ID
      try {
        const { topupCurrency, address, currency, original, feeRate, lower } = await getTopupInfo({ id, currency: chainCurrency })
        this.topupCurrency = topupCurrency
        this.address = address
        this.currency = currency
        this.original = original
        this.feeRate = feeRate
        this.lower = lower
        this.selectedCurrency = this.displayedCurrency.toUpperCase()
        this.getExRate()
        this.getBalance()
      } catch (e) {
        const resp = e.response
        if (/disabled/i.test(resp?.data?.message)) {
          console.warn(resp?.data?.message)
        }
        router.replace(addLocaleToPath(router.currentRoute.params.lang, `/card/${id}`)).catch(() => { })
      }
    },
    async handleConfirmClick () {
      if (!this.formValid) return
      if (this.CARD_NEW) {
        await this.firstTopup()
        this.$store.dispatch('CARD/GET_CARD')
      } else if (this.CARD_ACTIVE) {
        await this.topup()
      }
    },
    async firstTopup () {
      // const amount = this.calculateTotalAmount().toFixed(2, 1)
      const amount = this.amount
      const params = {
        orderId: this.orderId,
        amount,
        toAddress: this.address,
        fromTokenSymbol: this.selectedCurrency.toLowerCase(),
        toTokenSymbol: this.currency
      }
      if (this.isNotOrderCurrency()) {
        params.slippage = this.slippage
      }
      this.$store.dispatch('MODAL/OPEN', 'WaitingConfirm')
      this.$store.dispatch('CARD/BUY_CARD', params)
    },
    async topup () {
      const amount = this.amount
      const params = {
        cardId: this.CURRENT_ID,
        amount: amount,
        toAddress: this.address,
        fromTokenSymbol: this.selectedCurrency.toLowerCase(),
        toTokenSymbol: this.currency
      }
      if (this.isNotOrderCurrency()) {
        params.slippage = this.slippage
      }
      this.$store.dispatch('MODAL/OPEN', 'WaitingTopUpConfirm')
      this.$store.dispatch('CARD/TOPUP', params)
    },
    calculateTotalAmount () {
      if (this.selectedCurrency.toLowerCase() === this.currency.toLowerCase()) {
        try {
          return Big(this.amount)
        } catch (e) {
          return Big(0)
        }
      }
      if (!this.exRate || this.exRate === '-1') return Big(0)
      try {
        return Big(this.amount).times(this.exRate)
      } catch (e) {
        return Big(0)
      }
    },
    isNotOrderCurrency () {
      return this.selectedCurrency.toLowerCase() !== this.displayedCurrency
    },
    slippageValid () {
      if (!this.slippage) {
        this.slippageError = this.$t('top-up.error-msg.slippage-invalid')
        return false
      }
      try {
        const bigSlippage = Big(this.slippage)
        if (bigSlippage.gt(50) || bigSlippage.lt(0.1)) {
          this.slippageError = this.$t('top-up.error-msg.slippage-overflow')
          return false
        }
        this.slippageError = ''
        return true
      } catch (e) {
        this.slippageError = this.$t('top-up.error-msg.slippage-numeric')
        return false
      }
    },
    async getExRate (inputAmount = '1') {
      // inputAmount is not base value
      this.exRate = null
      if (!this.displayedCurrency) {
        this.exRate = null
      } else if (this.selectedCurrency.toLowerCase() === this.displayedCurrency) {
        this.exRate = '1'
      } else {
        try {
          const fromTokenSymbol = this.selectedCurrency
          const toTokenSymbol = this.currency?.toUpperCase()
          const amount = inputAmount
          const slippage = Big(this.slippage).toString()

          const result = await this.$store.dispatch('CARD/GET_SWAP', {
            chainId: this.CHAIN_ID,
            fromTokenSymbol,
            toTokenSymbol,
            amount,
            slippage,
            toAddress: this.address
          })
          if (result.fromToken.toLowerCase() === this.selectedCurrency.toLowerCase() &&
            result.currency === this.currency &&
            result.slippage === this.slippage.toString()) {
            if (!this.amount || (this.validAmount(this.amount) && Big(this.amount).eq(amount))) {
              Log('from', `${result.fromAmount} ${result.fromToken}`)
              Log('to', `${result.toToken}`)
              Log('exRate', `${result.exRate}`)
              this.exRate = result.exRate
              this.$store.commit('WALLET/SET_SWAP', result)
            }
          } else {
            this.exRate = '-1'
          }
        } catch (e) {
          this.exRate = '-1'
          // this.$store.dispatch('MODAL/ALERT', {
          //   // title: this.$t('top-up.rate-error.title'),
          //   text: this.$t('top-up.rate-error.text')
          //   // type: 'error'
          // })
          Log(e)
        }
      }
    },
    validAmount (val) {
      try {
        return Big(val).gt(0)
      } catch (e) {
        return false
      }
    },
    // async getAllowance () {
    //   this.allowance = await this.$store.dispatch('WALLET/GET_ALLOWANCE', {
    //     fromTokenSymbol: this.selectedCurrency.toLowerCase(),
    //     toTokenSymbol: this.currency
    //   })
    // },
    // async editAllowace () {
    //   try {
    //     await this.$store.dispatch('WALLET/APPROVE_ALLOWANCE', {
    //       fromTokenSymbol: this.selectedCurrency.toLowerCase(),
    //       toTokenSymbol: this.currency
    //     })
    //     this.getAllowance()
    //   } catch (e) {
    //     alert(e)
    //   }
    // },
    toCurrencyString
  }
}
</script>

<style lang="scss" scoped>
.form-wrap {
  @apply bg-[#F4F4F4] rounded-2xl lg:rounded-[32px] py-[30px] px-5 lg:px-10;
  // @apply grid grid-cols-12 gap-2 md:gap-4;
  @apply justify-center items-center mb-2;
  .form-flex {
    @apply flex flex-wrap gap-x-5;
  }
  // grid-template-columns: 3fr 2fr 4fr;
  .amount-field {
    @apply sm:flex flex-col space-y-1;
  }
  .total-amount-field {
    @apply max-w-full;
    .total-amount {
      @apply text-dark text-3xl lg:text-5xl lg:leading-[60px] font-heading flex relative;
    }
    .total-amount-value {
      @apply relative pl-5 lg:pl-0 w-0 grow truncate;
    }
  }
  .input-field {
    @apply relative;
    @apply block text-left;
    @apply bg-[#E6E6E6];
    @apply border-2 border-[#E6E6E6];
    @apply rounded-xl h-[60px] px-4 max-w-full;
    input {
      @apply bg-transparent;
      @apply focus:outline-none;
      @apply font-heading text-left text-dark text-3xl leading-[38px];
      @apply py-[9px] max-w-full;
    }
    span::after {
      @apply leading-[56px] font-semibold;
      content: '%';
      position: absolute;
      right: 1rem;
    }
    &.error {
      @apply border-2 border-red-400;
    }
  }
}
.actions {
  @apply flex flex-col md:flex-row justify-center items-center gap-3 self-center;
  @apply w-full;
  .agreement {
    @apply cursor-pointer select-none;
    @apply flex justify-center items-center space-x-2;
    @apply text-left;
    svg {
      @apply cursor-pointer;
    }
  }
}
.amount-warning,
.slippage-warning {
  @apply text-left;
  @apply text-sm text-red-400;
}
</style>
