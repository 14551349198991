<template>
  <div class="card-item" @click="goToCardDetail">
    <div
      class="
        grid
        grid-cols-[80px_auto]
        lg:grid-cols-[320px_auto_48px]
        gap-x-5
        lg:gap-x-[42px]
        gap-y-4
        lg:gap-y-5
      "
    >
      <div class="w-20 lg:w-80 lg:row-span-2">
        <simple-picture
          class="w-full"
          :src="programImage"
          :alt="$t('card.list.item.alt.card')"
          x="2"
        />
      </div>
      <div class="text-left font-semibold">
        <div v-if="card.expired" class="hidden sm:block float-right text-[#D59E57] leading-5">{{ $t('card.status.expired') }}</div>
        <div v-else-if="card.status === 'suspended'" class="hidden sm:block float-right text-[#D59E57] leading-5">{{ $t('card.status.suspended') }}</div>
        <div class="text-xs leading-5 lg:text-base">{{ $t('card.list.item.card-number') }}</div>
        <div class="font-heading lg:text-xl">{{ formatedCardNumber }}</div>
        <div v-if="card.expired" class="block sm:hidden text-[#D59E57] leading-5">{{ $t('card.status.expired') }}</div>
        <div v-else-if="card.status === 'suspended'" class="block sm:hidden text-[#D59E57] leading-5">{{ $t('card.status.suspended') }}</div>
      </div>
      <div
        class="font-semibold text-left col-span-2 lg:col-span-1 lg:col-start-2"
      >
        <template v-if="card.status !== 'suspended' && !card.expired">
          <div>{{ $t('card.list.item.card-balance') }}</div>
          <div
            class="
              font-heading
              text-2xl
              lg:text-3xl lg:leading-[38px]
              text-dark
              font-normal
              mb-2
            "
          >
            $ {{ toCurrencyString(card.balance, true) }}
          </div>
          <div>{{ $t('card.list.item.max-balance') }}</div>
          <div
            class="
              font-heading
              text-2xl
              lg:text-3xl lg:leading-[38px]
              text-dark
              font-normal
            "
          >
            $ {{ toCurrencyString(card.balanceUpper, true) }}
          </div>
        </template>
        <p v-else-if="card.expired">
          <i18n path="card.notice.expired-instruction" tag="span">
            <a class="text-link whitespace-nowrap" href="mailto:service@paywide.io">service@paywide.io</a>
          </i18n>
        </p>
        <p v-else-if="card.status === 'suspended'">
          <i18n path="card.notice.suspended-instruction" tag="span">
            <a class="text-link whitespace-nowrap" href="mailto:service@paywide.io">service@paywide.io</a>
          </i18n>
        </p>
      </div>
      <div class="relative hidden lg:block col-start-3 row-start-1 row-end-3">
        <img
          class="absolute top-1/2 -translate-y-1/2"
          src="@/assets/images/icons/angle-right.svg"
          :alt="$t('card.list.item.alt.detail')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { separateCardNumber } from '@/utils/format'
import { toCurrencyString } from '@/utils/currency-helper'

export default {
  name: 'CardItemActivated',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  computed: {
    formatedCardNumber () {
      return separateCardNumber(this.card.number)
    },
    programImage () {
      switch (this.card.program) {
        case 'business':
          return '@/assets/images/pricing-business-program.png'
        case 'classic':
        default:
          return '@/assets/images/pricing-classic-program.png'
      }
    }
  },
  methods: {
    toCurrencyString,
    goToCardDetail () {
      const path = this.localePath({ name: 'cardDetail', params: { id: this.card.id } })
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-item {
  @apply rounded-2xl lg:rounded-[32px] p-5 lg:p-10 min-h-[226px] lg:min-h-[282px] mb-4 lg:mb-5;
  .active {
    @apply bg-primary text-dark;
  }
  .abnormal {
    @apply bg-[#DD0000] text-white;
  }
  .inactive {
    @apply bg-[#E6E6E6] text-[#999999];
  }
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  &:hover {
    @apply cursor-pointer scale-[1.02] border-primary border-2;
  }
}
</style>
