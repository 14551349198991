// import { router } from '../router'
import { $vfm } from 'vue-final-modal'
import Log from '@/utils/log'
const state = {
  isLoading: false,
  popUpName: '',
  isModalOpen: false,
  currentModalName: ''
}

const mutations = {
  SET_MODAL_OPEN (state, payload) {
    state.currentModalName = payload
    state.isModalOpen = true
  },
  SET_MODAL_CLOSE (state) {
    state.currentModalName = ''
    state.isModalOpen = false
  }
}
const actions = {
  CLOSE ({ commit }, componentName) {
    $vfm.hideAll().then(() => {
      Log('after close modals')
    })
    commit('SET_MODAL_CLOSE')
  },
  OPEN ({ commit, state, dispatch }, componentName) {
    if (state.isModalOpen && componentName === state.currentModalName) {
      return
    }
    // 確保關閉其他 modal
    dispatch('CLOSE')
    $vfm.show({
      component: componentName,
      bind: {
        name: 'VDynamicAdvancedModal'
      },
      on: {
        // event by custom-modal
        confirm (close) {
          Log('confirm')
          close()
        },
        cancel (close) {
          Log('cancel')
          close()
        },
        // event by vue-final-modal
        'click-outside' () {
          Log('@click-outside')
        },
        'before-open' () {
          Log('@before-open')
        },
        opened () {
          Log('@opened')
        },
        'before-close' () {
          Log('@before-close')
        },
        closed () {
          Log('@closed')
          commit('SET_MODAL_CLOSE')
        }
      }
    })
    commit('SET_MODAL_OPEN', componentName)
  },
  ALERT ({ commit, state, dispatch }, params) {
    // if (state.isModalOpen && componentName === state.currentModalName) {
    //   return
    // }
    // 確保關閉其他 modal
    dispatch('CLOSE')
    $vfm.show({
      component: 'AlertTemplate',
      bind: {
        name: 'VDynamicAdvancedModal'
      },
      on: {
        // event by custom-modal
        confirm (close) {
          Log('confirm')
          close()
        },
        cancel (close) {
          Log('cancel')
          close()
        },
        // event by vue-final-modal
        'click-outside' () {
          Log('@click-outside')
        },
        'before-open' () {
          Log('@before-open')
        },
        opened () {
          Log('@opened')
        },
        'before-close' () {
          Log('@before-close')
        },
        closed () {
          Log('@closed')
          commit('SET_MODAL_CLOSE')
        }
      },
      params
    })
    commit('SET_MODAL_OPEN', 'AlertTemplate')
  }
}
const getters = {
  //
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
