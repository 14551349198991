<template>
  <div class="container">
    <div class="flex flex-col md:flex-row justify-center py-12">
      <div class="hidden md:block md:w-1/3 max-w-md">
        <img class="w-full" src="@/assets/images/no-card.png" alt="Not Found">
      </div>
      <div class="px-4 md:w-1/3 flex flex-col justify-center items-center text-center md:items-left md:text-left">
        <div class="heading text-4xl">{{ $t('not-found.oops') }}<br />{{ $t('not-found.title') }}</div>
        <div class="block md:hidden md:w-1/3 max-w-md">
          <img class="w-full" src="@/assets/images/no-card.png" alt="Not Found">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
