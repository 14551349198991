/* eslint-disable import/first */
/* eslint-disable vue/multi-word-component-names */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/tailwind.scss'
import 'vue-select/dist/vue-select.css'

// import fontawesome for media icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import vfmPlugin from 'vue-final-modal'
import '@/components/modals' // import modals and register components
import '@/components/icons' // import icons and register components
import vSelect from 'vue-select'
import { Pagination, Loading, Tooltip, Popover, Tabs, TabPane, Table, TableColumn } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Log from '@/utils/log'
import { globalMixin } from './utils/mixins'
import JazzIcon from 'vue-jazzicon'
import CustomDropdown from '@/components/CustomDropdown'
import DropdownItem from '@/components/CustomDropdownItem'
import SimplePicture from '@/components/SimplePicture'
import i18n from './i18n'
import loading from '@/utils/loading'
import VueLazyload from 'vue-lazyload'
import VueClipboard from 'vue-clipboard2'

// import VueGtag from 'vue-gtag'
// import VueMq from 'vue-mq'
import VueMeta from 'vue-meta'

// Vue.use(VueMq, {
//   breakpoints: { // default breakpoints - customize this
//     xs: 480,
//     sm: 640,
//     md: 768,
//     lg: 1024,
//     xl: Infinity
//   },
//   defaultBreakpoint: 'sm' // customize this for SSR
// })
Vue.use(VueClipboard)
Vue.use(VueMeta)
// Vue.use(VueGtag, {
//   config: { id: 'GTM-WKZ8P39' }
// })
Vue.component('dropdown', CustomDropdown)
Vue.component('dropdown-item', DropdownItem)
Vue.component('jazz-icon', JazzIcon)
Vue.component('simple-picture', SimplePicture)
// TODO: remove import simple picture in vue components

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false, trickle: false })
NProgress.inc(0)
Vue.use(NProgress)
Vue.use(Pagination)
Vue.use(Loading.directive)
Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.prototype.$loading = loading

Vue.component('v-select', vSelect)

library.add(fab, far, fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueLazyload)

// Vue.use(vfmPlugin)

Vue.mixin(globalMixin)

Vue.config.productionTip = false
// 如果要使用這個 Web3Modal 設計的按鈕，請解開這行註解
// Vue.config.ignoredElements = ['w3m-core-button']

Vue.prototype.$log = Log

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
