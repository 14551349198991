function importAll (r) {
  const images = {}
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item)
    return true
  })
  return images
}

export default importAll(require.context('./', false, /\.png$/))
