import { render, staticRenderFns } from "./TopUpFail.vue?vue&type=template&id=4265895c&scoped=true"
import script from "./TopUpFail.vue?vue&type=script&lang=js"
export * from "./TopUpFail.vue?vue&type=script&lang=js"
import style0 from "./TopUpFail.vue?vue&type=style&index=0&id=4265895c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4265895c",
  null
  
)

export default component.exports