import './style.scss'

function start () {
  if (document.querySelector('#pw-loading-container')) {
    return
  }
  const container = document.createElement('div')
  container.setAttribute('id', 'pw-loading-container')
  const bouncerContainer = document.createElement('div')
  bouncerContainer.className = 'pw-bouncer'
  for (let i = 0; i < 5; i++) {
    const bouncerItem = document.createElement('div')
    bouncerItem.className = 'pw-bouncer-line'
    bouncerContainer.appendChild(bouncerItem)
  }
  container.appendChild(bouncerContainer)
  const body = document.querySelector('body')
  // prevent body scrollable
  body.style.overflow = 'hidden'
  body.appendChild(container)
}

function end () {
  const container = document.querySelector('#pw-loading-container')
  if (container) {
    // if vue-final-modal stayed open, leave overflow: hidden on
    const keepOverflowHiddenSelectors = ['.vfm__container']
    let keepOverflowHidden = false
    for (let i = 0; i < keepOverflowHiddenSelectors.length; i++) {
      if (document.querySelector(keepOverflowHiddenSelectors[i])) {
        keepOverflowHidden = true
        break
      }
    }
    if (!keepOverflowHidden) {
      const body = document.querySelector('body')
      body.style.overflow = ''
    }
    container.remove()
  }
}

export default {
  start,
  end
}
