<template>
  <div class="card-item">
    <div
      class="grid grid-cols-[80px_auto] lg:grid-cols-[auto_48px] gap-x-5 lg:gap-x-[42px] gap-y-4 lg:gap-y-5"
    >
      <div
        class="relative font-semibold text-left col-span-2 lg:col-span-1 lg:row-span-2 lg:col-start-0"
      >
        <div class="lg:ml-2">
          <div class="text-dark text-xl pt-1">
            {{ $t("card.list.add.text") }}
          </div>
          <!-- <div>{{ $t('card.list.item.top-up-first') }}</div> -->
          <div>{{ $t("card.list.add.available", [leftCount]) }}</div>
        </div>
      </div>
      <div class="relative hidden lg:block col-start-2 row-start-1 row-end-3">
        <div class="rounded-full border-2 border-gray-600 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-8 h-8">
          <font-awesome-icon
            class="text-gray-600 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
            :icon="['fas', 'plus']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardItemAddNew',
  computed: {
    programImage () {
      return '@/assets/images/pricing-classic-program.png'
    },
    leftCount () {
      return this.$store.getters['CARD/LEFT_COUNT']
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.card-list .card-item {
  @apply rounded-2xl lg:rounded-[32px] p-5 lg:p-10 min-h-[100px] lg:min-h-[120px] mb-4 lg:mb-5;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  &:hover {
    @apply cursor-pointer scale-[1.02] border-primary border-2;
  }
}
</style>
