<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <div class="text-2xl leading-6 text-dark font-semibold text-center mb-8">{{ $t('modal.card-image.title') }}</div>
      <span class="">
        <!-- <div>My Wallet</div> -->
        <!-- Card images -->
      </span>
      <div
        v-if="cardImageLoading"
        v-loading="true"
        element-loading-background="rgba(0, 0, 0, 0.0)"
        class="bg-dark aspect-card rounded-[2rem]  my-4">
      </div>
      <div v-else class="rounded-[2rem] overflow-hidden my-4">
        <img :src="cardImgSrc" />
      </div>
      <div class="modal__content font-semibold">
        <!-- <slot v-bind:params="params"></slot> -->
        <div class="text-[#FF0000]">{{ $t('modal.card-image.aware') }}</div>
        <p v-html="$t('modal.card-image.content')" class="">
          <!-- The card information will show only one time and <span class="text-primary">can not</span> be retrieved after dismissed. Please be careful. -->
        </p>
      </div>
    </div>
    <div class="modal__action">
      <!-- <v-button @click="$emit('confirm', close)">confirm</v-button>
      <v-button @click="$emit('cancel', close)">cancel</v-button> -->
      <a
        class="btn btn-fixed-width"
        :class="{ disabled: !cardImgSrc }"
        :href="cardImgSrc"
        download="paywide.png"
        target="_blank"
      >
        {{ $t('button.download') }}
      </a>
      <button
        type="button"
        class="btn btn-fixed-width btn-light sm:order-first"
        :class="{ disabled: confirmDisabled }"
        @click="handleConfirmClick"
      >
        {{ $t('button.confirm') }}
        <span v-if="countDownTimer > 0">({{ countDownTimer }})</span>
      </button>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapGetters } from 'vuex'

export default {
  components: { VueFinalModal },
  name: 'PopUp',
  inheritAttrs: false,
  data () {
    return {
      countDownTimer: 10, // seconds
      confirmDisabled: true,
      interval: null,
      cardImage: ''
    }
  },
  async created () {
    await this.$store.dispatch('CARD/GET_CARD_IMAGE')
  },
  mounted () {
    // this.interval = setInterval(() => {
    //   this.countDownTimer = this.countDownTimer - 1000
    // }, 1000)
  },
  computed: {
    ...mapGetters('CARD', [
      'CARD_IMAGE'
    ]),
    cardImageLoading: function () {
      return !this.CARD_IMAGE
    },
    cardImgSrc () {
      if (this.CARD_IMAGE) {
        return 'data:image/png;base64,' + this.CARD_IMAGE
      }
      return null
    }
  },
  watch: {
    countDownTimer: function (value) {
      if (value <= 0) {
        this.confirmDisabled = false
        clearInterval(this.interval)
      }
    },
    CARD_IMAGE: function (value) {
      if (!this.CARD_IMAGE) return
      this.interval = setInterval(() => {
        this.countDownTimer = this.countDownTimer - 1
      }, 1000)
    }
  },
  methods: {
    async handleConfirmClick () {
      if (this.confirmDisabled) return
      this.confirmDisabled = true
      await this.$store.dispatch('CARD/GET_CARD')
      this.$store.dispatch('MODAL/CLOSE')
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
button {
  &.disabled {
    @apply cursor-not-allowed;
    // @apply opacity-25;
  }
}
.modal__action {
  @apply flex flex-col sm:flex-row gap-4;
  @apply p-4 md:p-4;
}
</style>
