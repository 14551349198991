import Vue from 'vue'
import Vuex from 'vuex'
import card from './card.module'
import modal from './modal.module'
import wallet from './wallet.module'
import i18n from '../i18n'
import router from '@/router'
import Log from '@/utils/log'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //
  },
  mutations: {
    SET_LANG (state, payload) {
      i18n.locale = payload
    }
  },
  actions: {
    async setLang ({ commit }, payload) {
      if (payload in i18n.messages) {
        commit('SET_LANG', payload)
        router.replace({ name: router.currentRoute.name, params: { lang: payload } })
          .catch(() => {})
      } else {
        // lazy load locale json
        try {
          const res = await import(`@/locales/${payload}.json`)
          i18n.setLocaleMessage(payload, res)
          commit('SET_LANG', payload)
          router.replace({ name: router.currentRoute.name, params: { lang: payload } })
            .catch(() => {})
        } catch (e) {
          Log(e)
        }
      }
      // commit('SET_LANG', payload)
      // router.replace({ name: router.currentRoute.name, params: { lang: payload } })
      //   .catch(() => {})
    }
  },
  getters: {
    //
  },
  modules: {
    CARD: card,
    MODAL: modal,
    WALLET: wallet
  }
})
