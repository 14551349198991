import Vue from 'vue'
import TopUpFail from './TopUpFail'
import TopUpComplete from './TopUpComplete'
import WaitingConfirm from './WaitingConfirm'
import CardImage from './CardImage'
import WaitingTopUpConfirm from './WaitingTopUpConfirm'
import Card3DSecure from './Card3DSecure'
import InvitationInput from './InvitationInput'
import AlertSuccess from './AlertSuccess'
import AlertTemplate from './AlertTemplate'

export default {
  WaitingConfirm,
  CardImage,
  Card3DSecure,
  TopUpFail,
  TopUpComplete,
  InvitationInput
}

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('WaitingConfirm', WaitingConfirm)
Vue.component('WaitingTopUpConfirm', WaitingTopUpConfirm)
Vue.component('CardImage', CardImage)
Vue.component('Card3DSecure', Card3DSecure)
Vue.component('TopUpComplete', TopUpComplete)
Vue.component('TopUpFail', TopUpFail)
Vue.component('InvitationInput', InvitationInput)
Vue.component('AlertSuccess', AlertSuccess)
Vue.component('AlertTemplate', AlertTemplate)
