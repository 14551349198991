import {
  getState, getCards, getCard, getOrder, getCardImage, pay, createOrder,
  topup, getNonce, auth, cancelTransaction, signOut,
  checkInvitationCode, activateUser,
  getSwapInfo, getConfirmations
} from '@/utils/api/card'
import { signMessage } from '@wagmi/core'
import Big from 'big.js'
// import router from '@/router'
import Log from '@/utils/log'
import NProgress from 'nprogress'
import i18n from '@/i18n'
// import { addLocaleToPath } from '@/utils/mixins'
const state = {
  userActive: false,
  cards: [],
  orders: [],
  leftCount: 0,
  // to tell the id is for card or order
  currentId: '',
  currentIdType: '',
  card: {},
  isLoading: false,
  status: null,
  img: '',
  orderCurrency: '',
  orderAmount: '',
  orderId: '',
  orderToAddress: '',
  referralCode: '',
  topupApiCompleted: false
}

const mutations = {
  SET_USER_ACTIVE (sate, payload) {
    state.userActive = payload
  },
  SET_CARDS (state, payload) {
    state.cards = payload.cards
    state.orders = payload.orders
    state.leftCount = payload.leftCount
  },
  SET_CARD_STATUS (state, payload) {
    state.card = payload
    state.status = payload.status
    // state.img = payload.img
  },
  SET_CARD_IMAGE (state, payload) {
    state.img = payload
  },
  SET_ORDER_ID (state, payload) {
    state.orderId = payload
  },
  SET_ORDER_TO_ADDRESS (state, payload) {
    state.orderToAddress = payload
  },
  SET_CURRENT_ID (state, payload) {
    state.currentId = payload.id
    state.currentIdType = payload.type
    Log('CARD/SET_CURRENT_ID', state.currentId, state.currentIdType)
  },
  SET_REFERRAL_CODE (state, payload) {
    state.referralCode = payload
  },
  SET_TOPUP_API_COMPLETED (state, payload) {
    state.topupApiCompleted = payload
  }
}
const actions = {
  async GET_STATE ({ commit, dispatch, rootGetters }) {
    try {
      Log('CARD/GET_STATE', rootGetters['WALLET/ADDRESS'])
      const { active } = await getState()
      commit('SET_USER_ACTIVE', active)
    } catch (e) {
      console.warn('Request get state error: ', e)
    }
  },
  async GET_STATE_ACTIONS ({ state, dispatch, commit, rootGetters }) {
    await dispatch('GET_STATE', null)
    if (!state.userActive) {
      dispatch('AUTO_ACTIVATE')
    } else {
      commit('SET_REFERRAL_CODE', '')
    }
  },
  async AUTO_ACTIVATE ({ state, dispatch, commit, rootGetters }) {
    const code = state.referralCode
    if (!code) {
      dispatch('MODAL/OPEN', 'InvitationInput', { root: true })
    } else {
      try {
        await checkInvitationCode(code)
        await activateUser(code)
        dispatch('CACHE_REFERRAL_CODE', '') // clear code after activated
        await dispatch('GET_STATE')
      } catch (e) {
        const error = e.response.data.message
        if (error.toLowerCase().includes('reach limit')) {
          // codeReachLimit
          dispatch('MODAL/OPEN', 'InvitationInput', { root: true })
        } else if (error.toLowerCase().includes('invalid code')) {
          // codeError: this.$t('modal.invitation.error-msg.invalid')
          dispatch('MODAL/OPEN', 'InvitationInput', { root: true })
        } else {
          dispatch('MODAL/ALERT', {
            title: this.$t('modal.invitation.alert.title'),
            text: this.$t('modal.invitation.alert.text'),
            type: 'error',
            // 照目前 input 關掉的執行
            closeCallback: this.disconnect
          })
        }
      }
    }
  },
  async AUTH ({ commit, dispatch, rootGetters }) {
    try {
      Log('CARD/AUTH', rootGetters['WALLET/ADDRESS'])
      const { nonce } = await getNonce()
      const { flatSig: payerSig } = await dispatch('SIGN_MESSAGE', nonce)
      commit('WALLET/SET_ACCOUNT_STATUS', true, { root: true })
      NProgress.start()
      await auth({ payerSig })
    } catch (e) {
      console.warn('Request sign message error: ', e)
      // dispatch('SIGN_OUT')
      commit('WALLET/SET_ACCOUNT_STATUS', false, { root: true })
      if (e.response && e.response.data && e.response.data.message) {
        dispatch('MODAL/ALERT', {
          title: i18n.t('modal.invitation.alert.title'),
          text: e.response.data.message,
          type: 'error'
        }, { root: true })
      }
    } finally {
      NProgress.done()
    }
  },
  async SIGN_OUT ({ commit, dispatch }) {
    Log('card/SIGN_OUT')
    commit('SET_USER_ACTIVE', false)
    // router.replace(addLocaleToPath(router.currentRoute.params.lang, '/')).catch(() => {})
    await signOut()
    dispatch('WALLET/RESET_APP', '', { root: true })
  },
  async GET_CARDS ({ commit, dispatch, rootGetters }) {
    try {
      const response = await getCards()
      // const { status } = response
      commit('SET_CARDS', response)
      // if (status === 'pending') {
      //   dispatch('MODAL/OPEN', 'WaitingConfirm', { root: true })
      // } else if (status === 'waiting to get' || status === 'active') {
      //   dispatch('MODAL/OPEN', 'PurchaseComplete', { root: true })
      // }
      commit('WALLET/SET_ACCOUNT_STATUS', true, { root: true })
      return response
    } catch (e) {
      if (e.response.status === 400) {
        commit('WALLET/SET_ACCOUNT_STATUS', true, { root: true })
        return { status: null }
      }
      console.warn('Error: ', e)
    }
  },
  async GET_CARD ({ state, commit, dispatch, rootGetters }) {
    try {
      const { currentId, currentIdType } = state
      const id = currentId
      let response
      if (currentIdType === 'order') {
        response = await getOrder(id)
      } else if (currentIdType === 'card') {
        response = await getCard(id)
      } else {
        commit('SET_CARD_STATUS', {})
        throw new Error('no current type availale')
      }
      commit('SET_CARD_STATUS', response)

      commit('WALLET/SET_ACCOUNT_STATUS', true, { root: true })
      return response
    } catch (e) {
      if (e.response.status === 400) {
        commit('WALLET/SET_ACCOUNT_STATUS', true, { root: true })
        return { status: null }
      }
      console.warn('Error: ', e)
    }
  },
  async GET_CARD_IMAGE ({ state, commit, dispatch, rootGetters }) {
    commit('SET_CARD_IMAGE', '')
    try {
      const response = await getCardImage({ id: state.currentId })
      const { img } = response
      commit('SET_CARD_IMAGE', img)
    } catch (e) {
      console.warn('Error: ', e)
    }
  },
  async CREATE_ORDER ({ commit, dispatch, rootGetters }, payload) {
    const currency = rootGetters['WALLET/CHAIN_CURRENCY']
    const response = await createOrder(currency)
    const { id } = response
    return id
    // dispatch('GET_STATE')
  },
  async DO_PAY ({ commit, dispatch }, data) {
    try {
      await pay(data)
      dispatch('MODAL/OPEN', 'WaitingConfirm', { root: true })
    } catch (e) {
      // TODO: open fail modal
      console.warn('Error: ', e)
      dispatch('MODAL/OPEN', 'TopUpFail', { root: true })
    }
  },
  async GET_SWAP ({ commit, state, dispatch }, data) {
    const { chainId, fromTokenSymbol, amount, toAddress, slippage } = data
    const fromTokenInfo = await dispatch('WALLET/GET_TOKEN_INFO', {
      tokenSymbol: fromTokenSymbol,
      chainId
    }, { root: true })
    const fromAmount = Big(amount).times(Big(10).pow(fromTokenInfo.decimals))
    const toTokenSymbol = data.toTokenSymbol.split('-')[0] // 'usdt-avaxc-erc20', 'usdt-matic-erc20'
    const requestData = {
      id: chainId,
      fromToken: fromTokenSymbol,
      fromAmount,
      toToken: toTokenSymbol,
      slippage
    }
    const response = await getSwapInfo(requestData)

    // check toAddress
    if (toAddress.toLowerCase() !== response.address.toLowerCase()) {
      throw new Error('toAddress does not match')
    }

    return response
  },
  async DO_TOPUP ({ commit, dispatch }, data) {
    try {
      await topup(data)
      // dispatch('MODAL/OPEN', 'WaitingTopUpConfirm', { root: true })
    } catch (e) {
      // TODO: open fail modal
      console.warn('Error: ', e)
      dispatch('MODAL/OPEN', 'TopUpFail', { root: true })
    }
    // commit('SET_CARD_STATUS', data)
  },
  async BUY_CARD ({ state, commit, dispatch, rootGetters }, data) {
    const { orderId, amount, slippage, toAddress, fromTokenSymbol, toTokenSymbol } = data
    const currency = toTokenSymbol.toLowerCase()
    const params = {
      amount,
      payee: toAddress,
      currency,
      fromTokenSymbol,
      toTokenSymbol
    }

    // for waiting
    commit('SET_TOPUP_API_COMPLETED', false)
    commit('WALLET/SET_TX_CURRENCY', currency, { root: true })
    commit('WALLET/SET_TX_HASH', null, { root: true })
    try {
      let tx
      if (slippage) {
        tx = await dispatch('WALLET/DO_TRADE_TOKEN', params, { root: true })
      } else {
        tx = await dispatch('WALLET/DO_TRADE', params, { root: true })
      }

      // for waiting
      commit('WALLET/SET_TX_HASH', tx.hash, { root: true })

      const payParams = {
        id: orderId,
        currency: toTokenSymbol.toLowerCase(),
        hash: tx.hash,
        nonce: tx.nonce
      }
      // get 1st tx and pay
      dispatch('DO_PAY', payParams)
      const txWait = await tx.wait()
      payParams.hash = txWait.transactionHash
      // await 2nd tx and pay again
      await dispatch('DO_PAY', payParams)

      commit('WALLET/SET_TX_HASH', txWait.transactionHash, { root: true })
      commit('SET_TOPUP_API_COMPLETED', true)
    } catch (e) {
      if (e.cancelled === false) {
        Log(e.replacement)
        const hash = e.replacement?.hash
        const payParams = {
          id: orderId,
          currency,
          hash,
          nonce: e.replacement?.nonce
        }
        await dispatch('CANCEL_TRANSACTION', orderId)
        // get 1st tx and pay
        await dispatch('DO_PAY', payParams)

        commit('WALLET/SET_TX_HASH', hash, { root: true })
        commit('SET_TOPUP_API_COMPLETED', true)
        return
      }
      console.warn('MetaMask: ', e)
      dispatch('CANCEL_TRANSACTION', orderId)
      dispatch('MODAL/OPEN', 'TopUpFail', { root: true })
    }
  },
  async CANCEL_TRANSACTION ({ state, rootGetters }, orderId) {
    const id = orderId
    const params = { id }
    try {
      await cancelTransaction(params)
    } catch (e) {
      // TODO: open fail modal
      console.warn('Error: ', e)
    }
  },
  async TOPUP ({ commit, dispatch, rootGetters }, data) {
    const { amount, slippage, toAddress, fromTokenSymbol, toTokenSymbol, cardId } = data
    const currency = toTokenSymbol.toLowerCase()
    const params = {
      amount,
      payee: toAddress,
      currency,
      fromTokenSymbol,
      toTokenSymbol: toTokenSymbol.split('-')[0]
    }
    // for waiting
    commit('SET_TOPUP_API_COMPLETED', false)
    commit('WALLET/SET_TX_CURRENCY', currency, { root: true })
    commit('WALLET/SET_TX_HASH', null, { root: true })
    try {
      let tx
      if (slippage) {
        tx = await dispatch('WALLET/DO_TRADE_TOKEN', params, { root: true })
      } else {
        tx = await dispatch('WALLET/DO_TRADE', params, { root: true })
      }

      // for waiting
      commit('WALLET/SET_TX_HASH', tx.hash, { root: true })

      const topupParams = {
        id: cardId,
        currency,
        hash: tx.hash,
        nonce: tx.nonce
      }
      // get 1st tx and pay
      dispatch('DO_TOPUP', topupParams)
      const txWait = await tx.wait()
      topupParams.hash = txWait.transactionHash
      // await 2nd tx and pay again
      await dispatch('DO_TOPUP', topupParams)

      commit('WALLET/SET_TX_HASH', txWait.transactionHash, { root: true })
      commit('SET_TOPUP_API_COMPLETED', true)
      // dispatch('MODAL/OPEN', 'TopUpComplete', { root: true })
    } catch (e) {
      if (e.cancelled === false) {
        Log(e.replacement)
        const hash = e.replacement?.hash
        const topupParams = {
          id: cardId,
          currency: toTokenSymbol.toLowerCase(),
          hash,
          nonce: e.replacement?.nonce
        }
        // get 1st tx and pay
        await dispatch('DO_TOPUP', topupParams)

        commit('WALLET/SET_TX_HASH', hash, { root: true })
        commit('SET_TOPUP_API_COMPLETED', true)
        // dispatch('MODAL/OPEN', 'TopUpComplete', { root: true })
        return
      }
      console.warn('MetaMask: ', e)
      dispatch('MODAL/OPEN', 'TopUpFail', { root: true })
    }
  },
  async SIGN_MESSAGE ({ commit, dispatch, rootGetters }, nonce) {
    Log('SIGN_MESSAGE')
    try {
      Log(nonce)
      const config = rootGetters['WALLET/CONFIG']
      const flatSig = await signMessage(config, { message: nonce })
      Log('SIGN_MESSAGE flatSig', flatSig)
      Log('SIGN_MESSAGE END')
      return { flatSig }
    } catch (e) {
      Log('SIGN_MESSAGE FAIL and SIGN_OUT')
      dispatch('SIGN_OUT')
      return null
    }
  },
  CACHE_REFERRAL_CODE ({ commit }, code) {
    commit('SET_REFERRAL_CODE', code)
  },
  async GET_CONFIRMATIONS ({ state, commit, dispatch, rootGetters }, currency) {
    try {
      const response = await getConfirmations(currency)
      return response
    } catch (e) {
      Log(e.stack)
    }
  }
}
const getters = {
  USER_ACTIVE: state => state.userActive,
  CARD_DETAIL: state => state.card,
  CARD_TRANSACTION_DESCRIPTION_DISPLAYED: state => state.card?.productId === '79174a29cd0d24a51927',
  CARD_OTP_ONLY_CODE: state => state.card?.productId === '79174a29cd0d24a51927',
  CARD_STATUS: state => state.status,
  CARD_NEW: state => state.status === 'new',
  CARD_PENDING: state => state.status === 'pending',
  CARD_ACTIVE: state => state.status === 'active',
  CARD_WAITING_TO_GET: state => state.status === 'waiting to get',
  CARD_SUSPENDED: state => state.status === 'suspended',
  CARD_EXPIRED: state => state.card?.expired,
  CARD_TOPUP_DISABLED: state => !!state.card?.topupDisabled,
  CARDS: state => state.cards,
  ORDERS: state => state.orders,
  LEFT_COUNT: state => state.leftCount,
  HAS_CARD: state => ['new', 'pending', 'waiting to get', 'active', 'suspended'].includes(state.status),
  HAS_CARDS: state => state.orders.length + state.cards.length > 0,
  ORDER_CURRENCY: state => state.orderCurrency,
  // ORDER_AMOUNT: state => state.orderAmount,
  ORDER_ID: state => state.orderId,
  CARD_IMAGE: state => state.img || '',
  SEPARATED_CARD_NUMBER: state => state.card.number?.match(/.{1,4}/g).join(' ') || '',
  ORDER_TO_ADDRESS: state => state.orderToAddress,
  CURRENT_ID: state => state.currentId,
  CURRENT_ID_TYPE: state => state.currentIdType,
  REFERRAL_CODE: state => state.referralCode,
  TOPUP_API_COMPLETED: state => state.topupApiCompleted
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
